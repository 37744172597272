import React from "react";
import { CSRFToken, templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { AuthBox } from "@client/containers/AuthBox";
import ImageIcons from "@client/constants/index";
import EmailInput from "@client/components/EmailInput";

export default (props: templates.ForgotPassword) => {
  const imageConstant = ImageIcons.UserLogin;

  return (
    <Scaffold title="forgot-password">

      <AuthBox>
        <div className="flex flex-col md:shrink-0 py-8">
          <p className="text-3xl font-semibold text-base-content">Forgot</p>
          <p className=" text-3xl font-semibold text-base-content">Your Password?</p>
          <p className="font-lghit text-sm  text-base-content py-2">
            No worries, We’ll send you instructions to reset
          </p>
        </div>
        <form className="" method="POST">

          <CSRFToken />
          <EmailInput
            name="email"
            placeholder="Email address"
            form={props.form}
          />

          <div className="grid grid-cols-1 mb-40">
            <button
              className="w-full h-10 sm:h-12 px-4 py-2 mr-5 font-medium text-white bg-primary rounded hover:shadow-md"
              type="submit"
            >
              Reset Password
            </button>
          </div>
          
        </form>
      </AuthBox>
    </Scaffold>
  );
};