import { getAllCategories, getAllSubCategories } from "@client/apis/Api";
import React, { Children, useEffect, useState } from "react";
interface MegaMenuProps {
  categoryId: string;
}
const MegaMenu = (props: MegaMenuProps) => {
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    allCategories();
  }, [props.categoryId]);

  const allCategories = async () => {
    try {
      const response = await getAllSubCategories(props.categoryId);
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-base-100 shadow-lg text-base-content">
      <ul className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  menu lg:justify-items-center h-52 overflow-y-auto ">
        {subCategories.map((item: any, index) => (
          <li key={index}>
            <ul>
              <li className="font-semibold hover:bg-transparent hover:font-bold ">
                <a
                  className="hover:bg-transparent "
                  href={`/products/?category=${item.pk}`}
                >
                  {item.name}
                </a>
              </li>

              {item.children.map((item: any) => (
                <li className="ml-3">
                  <a
                    className=" font-medium hover:bg-transparent hover:font-semibold"
                    href={`/products/?category=${item.pk}`}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MegaMenu;
