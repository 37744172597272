import React, { useState } from "react";

interface IRadioInputProps {
  name: string;
  options: { value: string, label: string }[];
  form: any;
}

const RadioInput = ({ name, options, form }: IRadioInputProps) => {
    const [inputValue, setInputValue] = useState(form.fields[name].widget.value || "0");
    const handleChange =(event:any) => {
        const value = event.target.value;
        setInputValue(value);
    }

  return (
    <>
        {options.map((option, index) => (
            <div key={index} className="flex items-center">
            <input
                name={name}
                type="radio"
                value={option.value}
                checked={inputValue === option.value}
                onChange={handleChange}
                className="radio radio-primary cursor-pointer scale-50"
            />
            <label className=" text-xs md:text-sm cursor-pointer">{option.label}</label>
            </div>
        ))}
    </>
  );
};
export default RadioInput;