import React, { useContext, useEffect, useState } from "react";
import ImageIcons from "@client/constants/index";
import FeatureBoundary from "@client/containers/FeatureBoundary";
import Logo from "@client/assets/icons_svg/Logo";
import {
  Fb,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from "@client/assets/icons_svg";
import { Context } from "@reactivated";
import { getAllCategories } from "@client/apis/Api";

const Footer = () => {
  const { feature } = useContext(Context);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    allCategories();
  }, []);

  // get categories
  const allCategories = async () => {
    try {
      const response = await getAllCategories();

      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("comment", categories);

  return (
    <>
      <div className="block  mt-16   md:px-12 lg:px-24 xl:px-0 bg-base-300">
        <footer className="grid md:grid-cols-12 justify-items-center footer gap-10 p-2 md:p-10  md:px-8 lg:px-20 text-base-content container mx-auto ">
          <div className=" md:col-span-3 ">
            <aside className="flex gap-3 flex-col ">
              <div className="flex md:mb-2 h-10 w-24">
                <Logo />
              </div>

              <p className="text-base-content text-xs lg:text-sm">
                Kochi, Kerala
              </p>
              <div className="grid grid-cols-5 justify-center items-center gap-4 md:gap-2 lg:gap-4 mt-2 justify-items-center ">
                <FeatureBoundary flag="user.socials.youtube">
                  <a href={feature["user.socials.youtube"]?.value}>
                    <Youtube className="text-md md:text-xl cursor-pointer" />
                  </a>
                </FeatureBoundary>

                <FeatureBoundary flag="user.socials.linkedin">
                  <a href={feature["user.socials.linkedin"]?.value}>
                    <Linkedin className="text-md md:text-xl cursor-pointer" />
                  </a>
                </FeatureBoundary>

                <FeatureBoundary flag="user.socials.twitter">
                  <a href={feature["user.socials.twitter"]?.value}>
                    <Twitter className="text-md md:text-xl cursor-pointer" />
                  </a>
                </FeatureBoundary>

                <FeatureBoundary flag="user.socials.facebook">
                  <a href={feature["user.socials.facebook"]?.value}>
                    <Fb className="text-md md:text-xl cursor-pointer" />
                  </a>
                </FeatureBoundary>
                <FeatureBoundary flag="user.socials.instagram">
                  <a href={feature["user.socials.instagram"]?.value}>
                    <Instagram className="text-md md:text-xl cursor-pointer hover:text-primary" />
                  </a>
                </FeatureBoundary>
              </div>
            </aside>
          </div>
          <div className="hidden md:block md:col-span-3">
            <nav className="flex flex-col  gap-2 ">
              <h6 className="text-base-content text-base lg:text-lg mb-2 font-semibold  ">
                Categories
              </h6>
              {categories.map((category) => {
                return (
                  <a
                    key={category.pk}
                    className="font-normal cursor-pointer text-xs lg:text-sm mb-1 text-base-content"
                    href={`/products/?category=${category.pk}`}
                  >
                    {category.name}
                  </a>
                );
              })}
            </nav>
          </div>
          <div className="hidden md:block md:col-span-3">
            <nav className="flex flex-col  gap-2">
              <h6 className="text-base-content text-base lg:text-lg mb-2 font-semibold  ">
                Useful Links
              </h6>
              <a className="font-normal cursor-pointer text-xs lg:text-sm mb-1 text-base-content">
                Terms of Service
              </a>
              <a className="font-normal cursor-pointer text-xs lg:text-sm mb-1 text-base-content">
                Privacy Policy
              </a>
              <a className="font-normal cursor-pointer text-xs lg:text-sm mb-1 text-base-content">
                Return Policy
              </a>
            </nav>
          </div>
          <div className="hidden md:block md:col-span-3">
            <nav className="flex flex-col  gap-2">
              <h6 className="text-base-content text-base lg:text-lg mb-2 font-semibold  ">
                Help
              </h6>
              <a
                className="font-normal cursor-pointer text-xs lg:text-sm mb-1 text-base-content"
                href="mailto:hi@vcreate.art"
              >
                hi@vcreate.art
              </a>
            </nav>
          </div>
        </footer>
      </div>
      <div className="hidden md:flex justify-center cursor-pointer text-base-content text-xs lg:text-sm font-normal my-4">
        <a className="col-span-1 px-4 border-r-2 border-base-300" href="#">
          Privacy Policy{" "}
        </a>
        <a className="col-span-1 px-4 border-r-2 border-base-300" href="#">
          Terms & Conditions
        </a>
        {/* TODO @roshna why are we using @ it should a copyright logo */}
        <span className="col-span-1 px-4">@ 2024 vCreate.art</span>
      </div>
    </>
  );
};

export default Footer;
