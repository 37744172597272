import OfferCards from "@client/components/OfferCards";
import ImageIcons from "@client/constants";

import React from "react";
import FeatureBoundary from "./FeatureBoundary";

const Offers = () => {
  return (
    <FeatureBoundary flag="core.home.offers">
      <div className="mt-8 md:mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  items-center gap-6 md:gap-3 lg:gap-6 lg:px-24 xl:px-28">
        <OfferCards
          description1="   Free delivery above ₹159"
          image={ImageIcons.HomePage.OfferImgOne}
          bgcolor="bg-[#77BA3A]"
          heading="  Free delivery"
          buttonText="  Shop Now"
          bgButtonColor="bg-[#9BCE6C]"
          description2="   "
        />
        <OfferCards
          description1="Organic food"
          image={ImageIcons.HomePage.OfferImgTwo}
          bgcolor="bg-[#BA3A3A]"
          heading="60% off"
          buttonText="See All"
          bgButtonColor="bg-[#CE6C6C]"
          description2="Save upto 60% off on your first order ."
        />
      </div>
    </FeatureBoundary>
  );
};

export default Offers
