import * as React from "react";
import type { SVGProps } from "react";
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.577 5.366v-.882A3.484 3.484 0 0 0 9.093 1h-4.61A3.484 3.484 0 0 0 1 4.484v10.524a3.484 3.484 0 0 0 3.483 3.484h4.619a3.475 3.475 0 0 0 3.475-3.474v-.892M19 9.746H7.615M16.231 6.99 19 9.746l-2.769 2.757"
    />
  </svg>
);
export default SvgLogout;
