import * as React from "react";
import type { SVGProps } from "react";
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.858 14.554c-1.193-3.723.2-7.977 4.108-9.236A6.67 6.67 0 0 1 15 6.331c1.616-1.25 3.968-1.672 6.022-1.013 3.908 1.259 5.31 5.513 4.119 9.236C23.285 20.454 15 24.998 15 24.998S6.775 20.522 4.858 14.554"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.444 9.111a3.09 3.09 0 0 1 2.13 2.691"
    />
  </svg>
);
export default SvgHeart;
