import React, { useState } from "react";
import { templates, CSRFToken } from "@reactivated";
import ImageIcons from "@client/constants/index";

// containers
import { Scaffold } from "@client/containers/Scaffold";
import { AuthBox } from "@client/containers/AuthBox";

// components
import Input from "@client/components/Input";
import PasswordInput from "@client/components/PasswordInput";
import EmailInput from "@client/components/EmailInput";
import FeatureBoundary from "@client/containers/FeatureBoundary";

export default (props: templates.UserRegistration) => {
  const [emailError, setEmailError] = useState(false);
  const imageConstant = ImageIcons.UserLogin;
  return (
    <Scaffold title="Register">
      <AuthBox>
        <p className="text-lg sm:text-xl lg:text-2xl font-medium text-base-content py-1 sm:py-2">
          Get started absolutely free
        </p>
        <form method="POST">
          <CSRFToken />
          <Input
            name="first_name"
            placeholder="Full Name"
            type="text"
            form={props.form}
          />
          <EmailInput
            name="email"
            placeholder="Email address"
            type="email"
            form={props.form}
            onEmailChange={setEmailError}
          />
          <PasswordInput
            label="password"
            name="password"
            placeholder="Password"
            type="password"
            form={props.form}
          />
          <PasswordInput
            label="password"
            name="confirm_password"
            placeholder="Confirm Password"
            type="password"
            form={props.form}
          />
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
            <button
              className={`w-full font-medium h-12 px-4 py-2 mr-5 text-primary-content bg-primary rounded hover:shadow-md ${
                emailError ? "opacity-50" : ""
              }`}
              disabled={emailError ? true : false}
              type="submit"
            >
              Sign Up
            </button>
            <FeatureBoundary flag="core.login.google">
              <button
                className="w-full h-12 px-2 py-1 font-semibold text-xs text-secondary bg-base-100 hover:shadow border border-base-300 rounded flex items-center justify-center"
                type="button"
              >
                <img src={imageConstant.GoogleIcon} alt="logo" className="" />
                Sign in with Google
              </button>
            </FeatureBoundary>
          </div>

          <div className="mb-1 sm:mb-3 py-1 sm:py-2">
            <p className="text-xs text-center text-neutral-700 py-2">
              By signing up, I agree to Terms of use and Privacy policy
            </p>
            <p className="text-xs sm:text-sm  text-center text-neutral-700">
              Already have an account ?{" "}
              <a
                href="/account/login/"
                className="text-xs sm:text-sm cursor-pointer text-primary "
              >
                Sign In
              </a>
            </p>
          </div>
        </form>
      </AuthBox>
    </Scaffold>
  );
};