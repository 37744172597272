import React, { useEffect, useState } from "react";

interface IErrorInfo {
  message: string;
  code: string;
}

export interface IPinCodeInputProps {
  name: string;
  placeholder: string;
  errors?: IErrorInfo[];
  form?:any;
  ErrorProp?: any;
}

const PinCodeInput = ({ name, placeholder, form, ErrorProp}: IPinCodeInputProps) => {
    const [inputValue, setInputValue] = useState(form.fields[name].widget.value ?? "");
    const [fieldError, setFieldError] = useState(form.errors?.[name]);


    useEffect(() => {
      ErrorProp(fieldError);
    }, [fieldError]);

    const handleChange = (event:any) => {
      const value = event.target.value;
      setInputValue(value);

      if (value){
        if (!/^\d{6}$/.test(value)) {
            setFieldError('Invalid pin code.');
        }else{
            setFieldError(form.errors?.[name] || "");
        }
      } else{
          setFieldError(form.errors?.[name] || "");
      }
    };
      
    return (
      <div>
        <input
          value={inputValue}
          onChange={handleChange}
          type="text"
          className={`px-4 py-2 w-full h-12 text-xs text-base-content border rounded focus:border-base-content focus:outline-none ${
            fieldError
              ? "hover:border-error"
              : "hover:border-base-content hover:border-opacity-40"
          } ${fieldError ? "border-error" : "border-neutral-300"}`}
          placeholder={placeholder}
          name={name}
        />
        <div className="flex min-h-5">
          <p className="text-error text-xs">{fieldError}</p>
        </div>
      </div>
    );
};

export default PinCodeInput;