import React, { useState } from "react";
import RatingComponent from "./RatingComponent";
import ImageIcons from "@client/constants/index";

interface TestimonialProps {
  name: String;
  comment: string;
  description: string;
  coloredStar: number;
  nonColoredStar: number;
}
const Testimonial = (props: TestimonialProps) => {
    const ImageConstants = ImageIcons.Testimonial;

  return (
    <div className="">
      <div
        className=" w-60 md:w-64 lg:w-full bg-base-100  scale-75 sm:scale-90 md:scale-95 lg:scale-90
       hover:scale-95  md:hover:scale-100   cursor-pointer 
        hover:border-primary rounded-t-[10px]   hover:border-b-8 border-solid  hover:shadow-xl  
        transition ease-in-out delay-100 hover:-translate-y-0.5p-[10px] duration-300 shadow-lg border-transparent"
      >
        <div className=" card-body">
          <p className="">
            <img src={ImageConstants.Quotes}/>
          </p>
          <div className="flex items-center mt-5">
            <RatingComponent
              coloredCount={props.coloredStar}
              nonColoredCount={props.nonColoredStar}
            />
          </div>
          <div className="text-neutral-600 text-xs md:text-sm font-semibold mt-2">
            {props.description}
          </div>
          <div className="flex gap-3 mt-4 items-center">
            <div>
              <img src={ImageConstants.Profile} alt="profile" />
            </div>
            <div>
              <p className="text-base md:text-lg text-base-content font-semibold truncate max-w-[150px]">
                {props.name}
              </p>
              <p className="text-neutral-600 text-xs md:text-sm font-medium">
                {props.comment}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
