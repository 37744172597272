import { useContext } from "react";
import { Context } from "@reactivated";
import { UpdateCart } from "@client/controllers/AddToCart";
import {
  AddToCartWithdynamicValue,
  AddToCartWithImageValue,
} from "@client/apis/Api";

interface IBaseAtTr {
  pk: number;
  input_type: number;
}

export interface IData {
  baseAttr?: IBaseAtTr;
  value: string;
  image: string;
  cartitem: number;
}

export const useCart = () => {
  const context = useContext(Context);

  return async (
    id: any,
    is_checkout: boolean,
    is_addToCartbtn: boolean,
    data?: IData
  ) => {
    const cart = await UpdateCart(id, is_checkout, is_addToCartbtn);
    console.log("cart",cart);

    context.setValue({
      ...context,
      cart_count: cart.cart?.cartitems?.length,
    });

    console.log('comment1', data?.baseAttr?.input_type);

    if (data?.baseAttr?.input_type !== undefined) {
      data.cartitem = cart.cartitem;
      if (data.baseAttr.input_type === 0) {
        try {
          const response = await AddToCartWithdynamicValue(
            data.baseAttr.pk,
            data
          );
          // const data = response.json()
          console.log("response", response);
        } catch (error: any) {
          console.error(error);
        }
      } else if (data.baseAttr.input_type === 1) {
        try {
          const response = await AddToCartWithImageValue(
            data.baseAttr.pk,
            data
          );
          console.log(response);
        } catch (error: any) {
          console.error(error);
        }
      }
    }
  };
};
