import React, { useContext } from "react";

import { Context } from "@reactivated";
import { Helmet } from "react-helmet-async";

interface IScaffoldProps {
  title: string;
  children: React.ReactNode;
}

export const Scaffold = ({ title, children }: IScaffoldProps) => {
  const { STATIC_URL } = useContext(Context);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${STATIC_URL}dist/index.css`}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />

        <script
          defer
          crossOrigin="anonymous"
          src={`${STATIC_URL}dist/index.js`}
        />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>

      <div >{children}</div>
    </>
  );
};
