import * as React from "react";
import type { SVGProps } from "react";
const SvgDiscount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    {...props}
  >
    <g clipPath="url(#discount_svg__a)">
      <path
        fill="url(#discount_svg__b)"
        d="M39.207 21.544a1.23 1.23 0 0 1 0-1.088l1.567-3.205A3.646 3.646 0 0 0 39.2 12.41l-3.151-1.672a1.23 1.23 0 0 1-.64-.88l-.616-3.514a3.646 3.646 0 0 0-4.118-2.992l-3.532.5a1.23 1.23 0 0 1-1.035-.337l-2.563-2.48a3.646 3.646 0 0 0-5.091 0l-2.564 2.48a1.23 1.23 0 0 1-1.035.337l-3.531-.5a3.646 3.646 0 0 0-4.119 2.993L6.59 9.858a1.23 1.23 0 0 1-.64.88L2.8 12.41a3.646 3.646 0 0 0-1.573 4.842l1.566 3.204a1.23 1.23 0 0 1 0 1.088l-1.567 3.204A3.646 3.646 0 0 0 2.8 29.59l3.15 1.672a1.23 1.23 0 0 1 .64.88l.616 3.513a3.644 3.644 0 0 0 4.119 2.993l3.532-.5a1.23 1.23 0 0 1 1.034.336l2.564 2.48a3.65 3.65 0 0 0 5.09 0l2.564-2.48c.277-.267.654-.39 1.035-.336l3.532.5a3.646 3.646 0 0 0 4.118-2.993l.616-3.513a1.23 1.23 0 0 1 .64-.88l3.15-1.672a3.646 3.646 0 0 0 1.574-4.842zM16.155 10.099a4.446 4.446 0 0 1 4.441 4.441 4.446 4.446 0 0 1-4.44 4.441 4.446 4.446 0 0 1-4.442-4.44 4.446 4.446 0 0 1 4.441-4.442m-2.292 19.75a1.207 1.207 0 0 1-1.713 0 1.21 1.21 0 0 1 0-1.712L28.137 12.15a1.211 1.211 0 0 1 1.713 1.713zm11.982 2.052a4.446 4.446 0 0 1-4.441-4.441 4.446 4.446 0 0 1 4.44-4.441 4.446 4.446 0 0 1 4.442 4.44 4.446 4.446 0 0 1-4.441 4.442"
      />
      <path
        fill="url(#discount_svg__c)"
        d="M25.845 25.441a2.02 2.02 0 0 0-2.019 2.019 2.02 2.02 0 0 0 2.019 2.018 2.02 2.02 0 0 0 2.018-2.018 2.02 2.02 0 0 0-2.018-2.019m-9.69-12.92a2.02 2.02 0 0 0-2.018 2.02c0 1.112.905 2.018 2.018 2.018a2.02 2.02 0 0 0 2.019-2.019 2.02 2.02 0 0 0-2.019-2.018"
      />
    </g>
    <defs>
      <linearGradient
        id="discount_svg__b"
        x1={21}
        x2={21}
        y1={0}
        y2={42}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset={1} stopColor="#77BA3A" />
      </linearGradient>
      <linearGradient
        id="discount_svg__c"
        x1={21}
        x2={21}
        y1={12.522}
        y2={29.478}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset={1} stopColor="#77BA3A" />
      </linearGradient>
      <clipPath id="discount_svg__a">
        <path fill="#fff" d="M0 0h42v42H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDiscount;
