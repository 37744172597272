import React from "react";

import ServiceCard from "@client/components/ServiceCard";
import FeatureBoundary from "./FeatureBoundary";

const FeaturedServices = () => {
  return (
    <FeatureBoundary flag="core.home.featuredServices">
      <div className="grid mt-16 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:px-24 gap-3  items-center justify-items-center mb-10 md:mb-0">
        <ServiceCard
          name="Best prices & offers"
          text="Orders $50 or more"
          image="/assets/icons/discount.svg"
        />{" "}
        <ServiceCard
          name="Free Delivery "
          text="24/7 amazing service"
          image="/assets/icons/delivery.svg"
        />
        <ServiceCard
          name="Easy return"
          text=" Within 30 days"
          image="/assets/icons/return-box.svg"
        />
      </div>
    </FeatureBoundary>
  );
};

export default FeaturedServices;