import * as React from "react";
import type { SVGProps } from "react";
const SvgYbFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fill="#fff" />
    <path
      fill="currentColor"
      d="M13.421 17.368 17.518 15l-4.097-2.368zm9.126-6.181c.103.37.174.868.221 1.5.056.631.08 1.176.08 1.65l.047.663c0 1.729-.127 3-.348 3.813-.197.71-.655 1.169-1.366 1.366-.37.103-1.05.174-2.092.22a53 53 0 0 1-2.834.08L15 20.526c-3.308 0-5.368-.126-6.182-.347-.71-.197-1.168-.655-1.365-1.366q-.153-.553-.221-1.5a19 19 0 0 1-.08-1.65L7.106 15c0-1.729.127-3 .348-3.813.197-.71.655-1.169 1.365-1.366.371-.103 1.05-.174 2.093-.221a53 53 0 0 1 2.834-.079L15 9.474c3.308 0 5.368.126 6.182.347.71.197 1.168.655 1.365 1.366"
    />
  </svg>
);
export default SvgYbFooter;
