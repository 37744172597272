import Carousal from "@client/components/Carousal";
import Testimonial from "@client/components/Testimonial";
import { CarouselItem } from "@client/components/ui/ui/carousel";
import React from "react";
import FeatureBoundary from "./FeatureBoundary";

interface ITestimonials {
  testimonials: any[];
}

const Testimonials = (props: ITestimonials) => {
  return (
    <FeatureBoundary flag="core.home.testimonials">
      <div className="grid items-center  md:px-10 lg:px-24 mt-16 mb-16 gap-6">
        <div className="flex items-center  justify-center  text-2xl font-bold text-base-content mb-4">
          What Our Beloved Customer Say About Us{" "}
        </div>
        <div className="  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 item-center justify-center gap-12 md:gap-12 lg:gap-12 ">
          <Carousal>
            {props.testimonials.map((item) => (
              <Testimonial
                name={item.name}
                description={item.description}
                coloredStar={item.rating}
                nonColoredStar={5 - item.rating}
                comment={item.comment}
              />
            ))}
          </Carousal>
        </div>
      </div>
    </FeatureBoundary>
  );
};

export default Testimonials;