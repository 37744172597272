import { AddToCart } from "@client/apis/Api";

export const UpdateCart = async (
  id: any,
  is_checkout: boolean,
  is_addToCartbtn: boolean
) => {
  const data = {
    product: id,
    quantity: 1,
  };
  try {
    const response = await AddToCart(data);

    if (response.status === 200) {
      const result = response.data;
      if (is_checkout) {
        window.location.href = "/checkout/";
      } else if (is_addToCartbtn) {
        window.location.reload();
      }
      return result;
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.href =
        window.location.origin +
        `/account/login/?next=${window.location.pathname}`;
    }
  }
};
