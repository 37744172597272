export default function getDiscountedPercentage(
  user: any,
  total_price: number
) {
  if (total_price < 500) {
    return 0;
  }

  return isDiscountableUser(user) ? 0.60 : 0;
}

function isDiscountableUser(user: any) {
  let emails = ["bibinpaul1993@gmail.com", "@codelynks.com"];

  return emails.some((email) => user.name.includes(email));
}
