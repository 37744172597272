import React, { useContext, useEffect, useState } from "react";
import { templates, Context } from "@reactivated";
import MegaMenu from "./MegaMenu";
import ImageIcons from "@client/constants/index";
import FeatureBoundary from "@client/containers/FeatureBoundary";

import { getAllCategories } from "@client/apis/Api";
import All from "@client/assets/icons_svg/All";
import {
  AddUser,
  Buy,
  DropDown,
  Heart,
  Login,
  Logout,
  Search,
} from "@client/assets/icons_svg";
import Logo from "@client/assets/icons_svg/Logo";

export const NavBar = () => {
  const { user } = useContext(Context);

  const ImageConstants = ImageIcons.Navbar;

  const [showMenu, setShowMenu] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const onClickOpenMenu = () => {
    setShowMenu(!showMenu);
  };
  const searchProduct = () => {
    window.location.href = ` /products/?search=${searchItem}`;
  };
  const openSearchBox = () => {
    setShowSearchBox(true);
  };
  const cancelSearch = () => {
    setShowSearchBox(false);
    setSearchItem("");
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchProduct();
    }
  };
  useEffect(() => {
    allCategories();
  }, []);

  // get categories
  const allCategories = async () => {
    try {
      const response = await getAllCategories();

      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const { cart_count, feature } = useContext(Context);

  const handleClickOutside = () => {
    setShowMenu(false);
  };

  return (
    <div className="h-[70px] md:h-[80px] lg:h-[80px] shadow-md shadow-base-500  w-full bg-base-100 sticky top-0 z-30 ">
      <nav className=" ">
        {/* screen sm */}

        {showSearchBox ? (
          <div className=" my-4 relative mx-8 md:hidden ">
            <input
              placeholder="Search Products....."
              className=" fade-in  flex items-center text-sm  rounded-[50px] w-full  h-10  bg-base-200 outline-none px-8 "
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="absolute  right-5 top-3" onClick={cancelSearch}>
              <img
                src={ImageConstants.CancelSearch}
                className=" w-3 h-3  cursor-pointer"
              />
            </div>
          </div>
        ) : (
          <div className="px-2 fade-in   grid justify-between grid-cols-2 items-center md:hidden  ">
            <div className="flex items-center">
              <button
                data-collapse-toggle="navbar-multi-level"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none  "
                aria-controls="navbar-multi-level"
                aria-expanded="false"
                onClick={onClickOpenMenu}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>

              <div className="p-3">
                <Logo />
              </div>
            </div>

            <div className="flex justify-center items-center gap-5">
              <div className="relative flex items-center  lg:hidden">
                <div
                  className=" cursor-pointer right-0  w-9 h-9  bg-primary  rounded-[50px] flex items-center justify-center"
                  onClick={openSearchBox}
                >
                  <Search className=" w-4 h-4 " />
                </div>
              </div>
              <a
                href={"/cart"}
                className="block py-2 text-base-content"
                aria-current="page"
              >
                <div className="relative">
                  <Buy className="w-8 h-8 text-base-content" />
                  <div className="bg-secondary  text-secondary-content px-2 rounded-full  absolute p-1 text-[10px]  top-[-11px] right-[-10px]">
                    {cart_count ? cart_count : "0"}
                  </div>
                </div>
              </a>

              <div className="">
                <details className="dropdown ">
                  <summary className="relative btn normal-case font-medium bg-base-100 text-sm border-0 focus:outline-none hover:bg-base-100 hover:border-0 hover:text-current">
                    <img
                      src={ImageConstants.ProfileIcon}
                      className="w-10 h-10 md:hidden lg:hidden absolute top-2"
                    />
                  </summary>
                  <ul className="p-3 shadow menu dropdown-content right-0 z-[1] bg-base-100 rounded-box w-52">
                    <li>
                      <a className="flex flex-row gap-3" href="/account/">
                        <img src={ImageConstants.ViewProfile} />
                        <div>View Profile</div>
                      </a>
                    </li>
                    <li>
                      {user.name ? (
                        <a
                          className="flex flex-row gap-3"
                          href="/account/logout/"
                        >
                          <img src={ImageConstants.Logout} />
                          <div>Logout</div>
                        </a>
                      ) : (
                        <a
                          className="flex flex-row gap-3"
                          href="/account/login/"
                        >
                          <img src={ImageConstants.Login} />
                          <div>Login</div>
                        </a>
                      )}
                    </li>
                  </ul>
                </details>
              </div>
            </div>
          </div>
        )}
        {/* screen md or greater */}
        <div
          className={
            showMenu
              ? " md:px-8 lg:px-16 w-full md:block md:w-auto flex justify-center shadow-lg"
              : "hidden  md:px-8 lg:px-16  w-full md:flex md:w-auto justify-between py-2 px-2"
          }
          id="navbar-multi-level"
        >
          <div className="hidden md:block p-3">
            <Logo />
          </div>

          <ul className="bg-base-100 flex items-center flex-col p-4 w-full md:w-auto md:p-0   rounded-lg  md:space-x-2 lg:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 mt-2 md:border-0  ">
            {categories.map((item: any) => {
              return (
                <li>
                  <details
                    className="dropdown "
                    onClick={() => {
                      if (item?.children?.length !== 0) {
                        setSelectedCategory(item.pk);
                        setShowMegaMenu(!showMegaMenu);
                      }
                    }}
                    // onBlur={() => setShowMegaMenu(false)}
                  >
                    <summary className="bg-base-100  text-base-content btn normal-case font-medium  text-sm border-0 focus:outline-none hover:bg-base-100  ">
                      {item?.children?.length !== 0 ? (
                        <span className="block text-xs lg:text-sm hover:text-primary">
                          {item.name}
                        </span>
                      ) : (
                        <a href={`/products/?category=${item.pk}`}>
                          <span className="block text-xs lg:text-sm hover:text-primary">
                            {item.name}
                          </span>
                        </a>
                      )}
                    </summary>
                  </details>
                </li>
              );
            })}
            <FeatureBoundary flag="core.nav.wishlist">
              <li className="block md:hidden text-xs lg:text-sm lg:hidden relative  items-center ">
                <a
                  href={"/wishlist"}
                  className="block py-2  text-base-content font-medium"
                  aria-current="page"
                >
                  Wishlist
                </a>
              </li>
            </FeatureBoundary>
            <div className="hidden md:flex  lg:flex relative text-xs lg:text-sm items-center ">
              <input
                placeholder="Search Products....."
                className="relative flex items-center px-4  rounded-[50px] lg:w-64 md:w-44 h-10  bg-base-200 outline-none md:hidden lg:block"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <input
                placeholder="Search....."
                className="relative  items-center px-4  rounded-[50px] lg:w-64 md:w-44 h-10  bg-base-200 outline-none hidden md:block lg:hidden"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <div
                className="absolute cursor-pointer right-0  w-9 h-9  bg-primary  rounded-[50px] flex items-center justify-center"
                onClick={searchProduct}
              >
                <Search className=" w-4 h-4 " />
              </div>
            </div>

            <FeatureBoundary flag="core.nav.wishlist">
              <li className="hidden md:block lg:block">
                <a
                  href={"/wishlist"}
                  className="block py-2 text-base-content"
                  aria-current="page"
                >
                  <Heart className="w-8 h-8 text-base-content" />
                </a>
              </li>
            </FeatureBoundary>

            <li className="hidden md:block lg:block">
              <a
                href={"/cart/"}
                className="block py-2 text-base-content relative"
                aria-current="page"
              >
                <Buy className="w-8 h-8 text-base-content" />
                {cart_count > 0 ? (
                  <div className="bg-secondary text-secondary-content shrink-0 grow-0 rounded-full px-2  font-extrabold absolute p-1 text-[10px] top-[-2px] right-[-8px]">
                    {cart_count}
                  </div>
                ) : (
                  ""
                )}
              </a>
            </li>
            <li className="hidden md:block ">
              <details className="dropdown ">
                <summary className="relative btn normal-case font-medium bg-base-100 text-sm border-0 focus:outline-none hover:bg-base-100 hover:border-0 hover:text-current">
                  <img
                    src={ImageConstants.ProfileIcon}
                    className="w-10 h-10 hidden md:block lg:block absolute top-2"
                  />
                </summary>
                <ul className="p-3 shadow menu dropdown-content right-0 z-[1] bg-base-100 rounded-box w-52">
                  {user.name ? (
                    <div>
                      <li className="hover:bg-neutral-100 rounded  ">
                        <a href="/account/" className="flex flex-row gap-3">
                          <AddUser className="w-5 h-5" />
                          <div>View Profile</div>
                        </a>
                      </li>
                      <li className="hover:bg-neutral-100  rounded">
                        <a
                          className="flex flex-row gap-3"
                          href="/account/logout/"
                        >
                          <Logout className="w-5 h-5" />
                          <div>Logout</div>
                        </a>
                      </li>
                    </div>
                  ) : (
                    <li className="hover:bg-neutral-100  rounded">
                      <a className="flex flex-row gap-3" href="/account/login/">
                        <Login className="w-5 h-5" />
                        <div>Login</div>
                      </a>
                    </li>
                  )}
                </ul>
              </details>
            </li>
          </ul>
        </div>
        {showMegaMenu ? <MegaMenu categoryId={selectedCategory} /> : ""}
      </nav>
    </div>
  );
};
