import * as React from "react";
import type { SVGProps } from "react";
const SvgFbFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fill="#fff" />
    <path
      fill="#fff"
      d="M18.947 7.895h-1.579c-1.973 0-3.158 1.184-3.158 3.158v9.473"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={4}
      d="M18.947 7.895h-1.579c-1.973 0-3.158 1.184-3.158 3.158v9.473"
    />
    <path fill="#fff" d="M11.842 14.21h5.526z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={4}
      d="M11.842 14.21h5.526"
    />
  </svg>
);
export default SvgFbFooter;
