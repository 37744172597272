import React, { useEffect, useState } from "react";
import ImageIcons from "@client/constants/index";
import {
  AddUser,
  Buy,
  Location,
  Lock,

} from "@client/assets/icons_svg";
const SideNavbar = () => {
  const [pathname, setPathName] = useState("");
  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);
  const ImageConstants = ImageIcons.SideNavbar;
  const menuItems = [
    {
      name: "Profile",
      icon: <AddUser className="text-base-content w-5 h-5" />,
      path: "/account/",
    },
    {
      name: "Address",
      icon: <Location className="text-base-content w-6 h-6" />,
      path: "/account/addresses/",
    },
    {
      name: "My Orders",
      icon: <Buy className="text-base-content w-6 h-6" />,
      path: "/orders/",
    },
    {
      name: "Change Password",
      icon: <Lock className="text-base-content w-6 h-6" />,
      path: "/account/password/change/",
    },
  ];

  return (
    <div className="w-full ">
      {menuItems.map((item, index) => (
        <>
          <a
            key={index}
            className={
              pathname.trim() === item.path.trim()
                ? "flex  gap-1 items-center bg-base-300  text-sm cursor-pointer px-1 py-4 md:p-4"
                : "flex  gap-1 items-center hover:bg-base-300  text-sm cursor-pointer px-1 py-4 md:p-4"
            }
            href={item.path}
          >
            {item.icon}
            <span className="hidden md:block">{item.name}</span>
          </a>
        </>
      ))}
    </div>
  );
};

export default SideNavbar;
