import * as React from "react";
import type { SVGProps } from "react";
const SvgReturnBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 42 42"
    {...props}
  >
    <path
      fill="url(#return-box_svg__a)"
      fillRule="evenodd"
      d="m18.67 1.587 5.31 3.066L6.766 14.59v7.364c0 .294.146.547.4.694l3.99 2.304a.39.39 0 0 0 .401 0c.127-.074.2-.2.2-.347v-7.133l17.213-9.938L34.28 10.6c.51.294.801.8.801 1.388v9.211c-1.44-.486-2.966-.748-4.49-.841v-.618c0-.673-.383-1.276-.993-1.56a1.72 1.72 0 0 0-1.835.239l-6.11 5.115a2.12 2.12 0 0 0-.764 1.704c.021.663.335 1.258.87 1.651l6.091 4.469a1.72 1.72 0 0 0 1.798.147c.582-.295.944-.883.944-1.536v-.682c1.267.151 2.522.54 3.553 1.287q.299.216.565.471a1.6 1.6 0 0 1-.43.355l-15.61 9.013a1.57 1.57 0 0 1-1.603 0L1.457 31.4c-.509-.295-.8-.8-.8-1.389V11.988c0-.589.291-1.094.8-1.388l15.61-9.013a1.57 1.57 0 0 1 1.603 0m10.61 26.311c8.57 0 11 8.187 3.96 11.867a.08.08 0 0 0-.038.106.08.08 0 0 0 .095.046c11.697-2.877 10.88-18.286-4.018-18.286v-1.89a.4.4 0 0 0-.236-.372.4.4 0 0 0-.437.057l-6.112 5.115a.8.8 0 0 0-.293.655c.008.26.125.48.335.635l6.09 4.468a.4.4 0 0 0 .428.036.4.4 0 0 0 .225-.366z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="return-box_svg__a"
        x1={21}
        x2={21}
        y1={1.367}
        y2={40.633}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset={1} stopColor="#77BA3A" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgReturnBox;
