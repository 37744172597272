import * as React from "react";
import type { SVGProps } from "react";
const SvgHoverButton = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 63 62"
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="currentColor"
        d="M.605 30.798C.716 13.817 14.623.09 31.605.202c16.982.11 30.708 14.018 30.597 31s-14.019 30.708-31 30.596C14.22 61.688.494 47.78.605 30.798"
      />
      <path
        fill="#fff"
        d="m32.348 23-1.61 1.609 5.754 5.7H21v2.27h15.49l-5.752 5.702 1.61 1.609 8.522-8.446z"
      />
    </g>
  </svg>
);
export default SvgHoverButton;
