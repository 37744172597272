import React, { useCallback, useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import SideNavbar from "@client/components/SideNav";
import PersonalInformation from "@client/components/PersonalInformation";

export default (props: templates.UserProfile) => {
  const { user } = useContext(Context);
  return (
    <Scaffold title="user profile">
      <NavBar />
      <div className="md:mx-auto container">
        <div className="grid grid-cols-12 mt-16 md:px-16 relative min-h-[500px]">
          <div className=" bg-base-100 mt-10 md:mt-16  absolute top-0  left-0 border border-t-8 border-t-primary rounded border-base-200">
            <SideNavbar />
          </div>
          <div className="mt-10 md:mt-16 col-span-12  ml-10 md:ml-40 lg:ml-48  lg:mr-12">
            <PersonalInformation profile={props} />
          </div>
        </div>
      </div>
      <div className="lg:px-20 xl:px-24  hidden lg:block ">
        <Footer />
      </div>
    </Scaffold>
  );
};
