import React, { useState } from "react";

interface IErrorInfo {
  message: string;
  code: string;
}

export interface IEmailInputProps {
  name: string;
  placeholder: string;
  errors?: IErrorInfo[];
  type?: string;
  onEmailChange?:any;
  form?:any;
  className? : string;
}

const EmailInput = ({ name, placeholder, type, className, form, onEmailChange  }: IEmailInputProps) => {
    const [inputValue, setInputValue] = useState(form?.fields[name].widget.value ?? "");
    const [fieldError, setFieldError] = useState(form?.errors?.[name]);

    const handleChange = (event:any) => {
      const value = event.target.value;
      setInputValue(value);

      let isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      if(value && !isValidEmail){
          setFieldError("Invalid email format.")
          onEmailChange(true);
        }else{
          setFieldError(form && form.errors ? form.errors[name] : "");
          onEmailChange(false);
        }
      }

    return (
        <div>
            <input
            value={inputValue}
            onChange={handleChange}
            type="email"
            className={`px-4 py-2 w-full h-12 text-xs text-base-content border rounded focus:border-base-content focus:outline-none ${fieldError ? 'hover:border-error' : 'hover:border-base-content hover:border-opacity-40'} ${fieldError ? 'border-error' : 'border-baseContent'} ${className}`}
            placeholder={placeholder}
            name={name}
            />
            <div className="flex min-h-5">
            <p className="text-error text-xs">{fieldError}</p>
            </div>
      </div>
    );
};
export default EmailInput;