import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { AuthBox } from "@client/containers/AuthBox";

export default (props: templates.PasswordResetDone) => {
  return (
    <Scaffold title="forgot-password">

      <AuthBox>
        <div className="text-base-content flex flex-col md:shrink-0  items-center pb-10 sm:pb-14 mt-14 mb-44">

          <p className="text-3xl text-base-content font-semibold flex items-center justify-center ">
            Password Reset
          </p>
          <p className="text-neutral-400 py-4 sm:py-2 text-sm sm:text-base text-center flex items-center justify-center">
            Your password has been successfully reset
          </p>

          <p className="text-neutral-400 pb-3 text-sm sm:text-base text-center flex items-center justify-center ">
            Click here to continue to login
          </p>
          

          <a
            href="/account/login/"
            className="p-2 h-10 sm:h-12 w-full bg-primary text-white rounded text-base font-medium flex justify-center items-center hover:shadow-sm"
          >
            Continue
          </a>

        </div>
      </AuthBox>

    </Scaffold>
  );
};