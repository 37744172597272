import React, { useState } from "react";

interface IErrorInfo {
    message: string;
    code: string;
  }

interface ITextAreaProps{
    name: string;
    placeholder: string;
    errors?: IErrorInfo[];
    form? : any;
}


const TextArea = ({ name, placeholder,errors,form}:ITextAreaProps) => {

    const [inputValue, setInputValue] = useState(form.fields[name].widget.value ?? "");
    const fieldError = form.errors?.[name]

    const handleChange =(event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInputValue(value);
    }

    return (
      <>
        <textarea
          value={inputValue}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          className={`col-span-full min-h-28 px-4 py-2 border text-xs rounded focus:border-base-content text-base-content focus:outline-none md:col-span-2 ${
            fieldError
              ? "hover:border-error"
              : "hover:border-base-content hover:border-opacity-40"
          } ${fieldError ? "border-error" : "border-neutral-300"}`}
        />
        <div className="col-span-full min-h-5">
          {<p className="text-error text-xs">{fieldError}</p>}
        </div>
      </>
    );
};
export default TextArea;