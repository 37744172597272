import React, { useCallback, useContext, useEffect } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import ButtonComponent from "@client/components/ButtonComponent";
import ImageIcons from "@client/constants/index";

export default (props: templates.SuccessPage) => {

  const { user } = useContext(Context);

  const goToHome = () => {
    window.location.href = `/`;
  };
 const imageConstant = ImageIcons.SuccessPage;

  return (
    <Scaffold title="Home">
      <NavBar />
      <>
        <div className="flex flex-col items-center justify-center h-screen text-center gap-y-4 md:gap-y-6 ">
          <img
            src={imageConstant.PaymentSuccsess}
            alt=""
            className="w-24 h-24 md:w-32 md:h-32"
          />
          <div className="flex flex-col gap-y-1 md:gap-y-2 w-52 md:w-64">
            <p className="text-base-content text-base font-bold md:text-xl">
              Payment Successful
            </p>
            <p className="text-base-content text-xs font-medium md:text-sm">
              Your payment was successful! You can now continue shopping.
            </p>
          </div>
          <ButtonComponent
            buttonText="Go to Home"
            coloredButton={true}
            onClick={goToHome}
          />
        </div>
      </>
      <Footer />
    </Scaffold>
  );
};
