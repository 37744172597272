import React, {useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import AddressForm from "@client/components/AddressForm";
import SideNavbar from "@client/components/SideNav";
import Footer from "@client/components/Footer";



export default (props: templates.AddUserAddress) => {

  const { user } = useContext(Context)

  return (
    <Scaffold title="Add address">
      <NavBar />

      <div className="mx-auto container">
        <div className="grid grid-cols-12 mt-16 md:px-16 relative min-h-[500px]">
          <div className="bg-base-100 mt-10 md:mt-16  absolute top-0  left-0 border border-t-8 border-t-primary rounded border-gray-200">
            <SideNavbar />
          </div>
          <div className="mt-10 col-span-12  ml-12 md:ml-40">
            <div className="  shadow-square-shadow lg:p-7 md:p-4 p-2">
              <AddressForm form={props.form} />
            </div>
          </div>
        </div>
        <Footer />
      </div>

    </Scaffold>
  );
}
