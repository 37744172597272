import React, { useCallback, useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import ButtonComponent from "@client/components/ButtonComponent";
import Footer from "@client/components/Footer";
import ImageIcons from "@client/constants/index";

export default (props: templates.FailurePage) => {
  const { user } = useContext(Context);

  const goBackToCheckout = () => {
    window.location.href = `/checkout/`;
  };
 const imageConstant = ImageIcons.PaymentFailure;

  return (
    <Scaffold title="Home">
      <NavBar />
      <>
        <div className="flex flex-col items-center justify-center h-screen text-center gap-y-4 md:gap-y-6 ">
          <img
            src={imageConstant.PaymentFailure}
            alt=""
            className="w-24 h-24 md:w-32 md:h-32"
          />
          <div className="flex flex-col gap-y-1 md:gap-y-2 w-52 md:w-64">
            <p className="text-secondary text-base font-bold md:text-xl">
              Payment Unsuccessful
            </p>
            <p className="text-secondary text-xs font-medium md:text-sm">
              Something went wrong we couldn’t process your payment.
            </p>
          </div>
          <ButtonComponent
            buttonText="Go Back"
            coloredButton={true}
            onClick={goBackToCheckout}
          />
        </div>
      </>
      <Footer />
    </Scaffold>
  );
};
