import React, { useCallback, useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import SideNavbar from "@client/components/SideNav";
import Footer from "@client/components/Footer";
import ButtonComponent from "@client/components/ButtonComponent";

export default (props: templates.OrderHistoryDetailsPage) => {
  const { user } = useContext(Context);

  const ItemCount = () => {
    let item_count = 0;
    props.order_items.orderitems.map((item) => {
      item_count += item.quantity;
    });
    return item_count;
  };

  const OrderDate = (orderDate: any) => {
    const parsedDate: Date = new Date(orderDate);
    const month: string = parsedDate.toLocaleString("default", {
      month: "long",
    });
    const day: number = parsedDate.getDate();
    const year: number = parsedDate.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  console.log(props.order_items);

  return (
    <Scaffold title="my order details">
      <NavBar />
      <div className="mx-auto container ">
        <div className=" mt-28 md:pl-16 relative min-h-[500px] ">
          <div className=" text-base-content font-bold text-base p-2">
            Order Id: {props.order_items.razorpay_order_id}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 p-5 border-neutral-400 rounded-sm  border justify-center gap-5 md:justify-items-center">
            <div className="flex flex-col">
              <div className="text-xs py-1  text-neutral-700">Item Count</div>
              <div className=" font-semibold text-base-content ">
                {ItemCount()} items
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-xs py-1  text-neutral-700">Date Placed</div>
              <div className=" font-semibold text-base-content ">
                {OrderDate(props.order_items.order_date)}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-xs py-1  text-neutral-700">Total Cost</div>
              <div className=" font-semibold text-base-content ">
                ₹ {props.order_items.total_cost}
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="text-xs py-1  text-neutral-700">Status</div>
              <div
                className={`font-semibold ${
                  props.order_items.status === 0
                    ? "text-warning"
                    : props.order_items.status === 1 ||
                      props.order_items.status === 2 ||
                      props.order_items.status === 3
                    ? "text-primary"
                    : "text-error"
                }`}
              >
                {props.order_items.status === 0
                  ? "PENDING"
                  : props.order_items.status === 1
                  ? "PROCESSING"
                  : props.order_items.status === 2
                  ? "SHIPPED"
                  : props.order_items.status === 3
                  ? "DELIVERED"
                  : "CANCELLED"}
              </div>
            </div>
          </div>
          {props.order_items.orderitems.map((item) => (
            <>
              <div className="grid grid-cols-3 text-center p-4 justify-center sm:justify-normal ">
                <div className="font-medium sm:text-left">
                  {item?.product.name}
                </div>
                <div className="text-sm">Qty:{item?.quantity}</div>
                <div className="text-sm text-nowrap">
                  Total : ₹ {item?.quantity * item?.product.price}{" "}
                </div>
              </div>
              <div className="divider divide-x-[1px] m-0"></div>
            </>
          ))}
          <div className="grid grid-cols-1 md:grid-cols-2 md:justify-items-end">
            <div></div>
            <div className=" md:w-40  mt-3">
              <button className=" w-full p-3 rounded-md text-sm  normal-case text-primary border border-primary hover:shadow-lg">
                REORDER
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-20 xl:px-24  hidden lg:block ">
        <Footer />
      </div>
    </Scaffold>
  );
};
