import React, { Key, ReactNode, useState } from "react";
import ImageIcons from "@client/constants/index";
import { Delete } from "@client/assets/icons_svg";

interface IorderSummary {
  cartData: {
    id: number;
    images: any;
    name: ReactNode;
    count: ReactNode;
    price: ReactNode;
    key: any;
    value: any;
    is_dynamic: boolean;
    dynamic_image_value: any;
    dynamic_product_label: string;
    dynamic_value: string;
  }[];
  handleIncrement: (id: number) => void;
  handleDecrement: (id: number, item_count: ReactNode) => void;
  removeCartItem?: (id: number) => void;
  handleToggle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedItems?: Array<number>;
  hasSelectInput?: boolean;
  isCheckout?: boolean;
}


function getDynamicAttrValue(attributes: any[], cart?: any) {
  if (!attributes || attributes.length === 0) {
    return;
  }

  let d = attributes?.filter((item) => item?.is_dynamic);
  // TODO @ivin this filtering should come form the backend it's not working right now
  d = d[0]?.attributes[0]?.dynamic_values?.filter((item) =>
    cart?.cartitems?.some((cart_item) => cart_item?.pk === item?.cartitem?.id)
  );
  return d[0]?.value;
}

function getDynamicAttrImage(item: any) {
  let d = item?.attributes.filter(attr => attr.base.label = 'images')

  d = d[0]?.dynamic_image_value.filter(img => img?.cartitem?.id == item.id)
  return d[0]?.value;


  return null;
}

const OrderSummary = (props: IorderSummary) => {
  const ImageConstants = ImageIcons.OrderSummary;


  return (
    <div className="">
      {props.cartData.map((item, index) => (
        <div
          className="py-3    lg:border-b-[1px] lg:border-base-300"
          key={item.id}
        >
          <div
            className={`grid ${
              props.isCheckout
                ? `grid-cols-2 md:grid-cols-2 lg:grid-cols-4 `
                : `grid-cols-2 md:grid-cols-3 lg:grid-cols-6 `
            }  gap-6  justify-center lg:gap-10 items-center border border-base-300 p-5 lg:p-0 lg:border-none rounded-lg`}
          >
            <div className=" flex  items-center order-first md:pl-3 gap-3">
              {props.hasSelectInput ? (
                <div className="">
                  <input
                    type="checkbox"
                    name=""
                    checked={props.selectedItems?.includes(item.id)}
                    onChange={() => props.handleToggle(item.id)}
                    className="w-4 h-4 border-primary rounded checkbox checkbox-primary "
                  />
                </div>
              ) : (
                ""
              )}

              <img
                src={
                  item.images.length > 0
                    ? item.images[0]?.image?.file
                    : ImageConstants.DefaultImg
                }
                className="h-20 object-scale-down"
              />
            </div>
            <div
              className={`flex flex-col text-base-content text-base  order-2 gap-2 ${
                props.isCheckout ? `md:col-span-1` : `md:col-span-2`
              }`}
            >
              {item.name}

              {item?.attributes?.map((attribute: any, index: any) => (
                <div>
                  {attribute?.base.is_dynamic ? (
                    <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-2">
                      <p className="text-xs text-neutral-400">
                        {attribute?.base?.label}
                      </p>
                      <p className="text-xs font-semibold text-neutral-600">
                        : {attribute?.dynamic_values[0]?.value}
                      </p>
                    </div>
                  ) : null}
                </div>
              ))}

              {item?.attributes?.map((attribute: any, index: any) => (
                <div>
                  {attribute?.dynamic_image_value[0]?.value ? (
                    <a
                      className="text-xs text-primary underline break-words"
                      href={attribute?.dynamic_image_value[0]?.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="avatar">
                        <div className="w-8 rounded">
                          {/* <img src={attribute?.dynamic_image_value[0]?.value} /> */}
                          <img src={getDynamicAttrImage(item)} />
                        </div>
                      </div>
                    </a>
                  ) : null}
                </div>
              ))}

              <span className="text-base-content text-base font-bold md:hidden"></span>
            </div>
            <div className="border order-3 border-neutral-300 items-center flex justify-center gap-3 rounded-2xl px-3 md:px-2 xl:px-4 text-base-content font-medium py-2 md:min-w-20 lg:w-auto">
              {item?.is_dynamic ? null : item.count === 1 &&
                !props.isCheckout ? (
                <button
                  className="min-w-4 min-h-4 max-w-4 max-h-4"
                  onClick={() => props.handleDecrement(item.id, item.count)}
                >
                  <Delete className="text-base-content" />
                </button>
              ) : (
                <button
                  className=""
                  onClick={() => props.handleDecrement(item.id, item.count)}
                >
                  -
                </button>
              )}

              <span> {item.count}</span>

              {item?.is_dynamic ? null : (
                <button
                  className=""
                  onClick={() => props.handleIncrement(item.id)}
                >
                  +
                </button>
              )}
              {/* </div> */}
            </div>
            <div className="text-base-content text-base order-4   md:block">
              ₹{item.price * item.count}
            </div>
            {props.isCheckout ? (
              ""
            ) : (
              <div className="order-5">
                <div
                  className="text-base-content cursor-pointer  min-[1128px]:text-base
           max-[1023px]:text-base lg:text-sm"
                  onClick={() => props.removeCartItem(item.id)}
                >
                  Remove
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderSummary;
