import React, { useEffect, useState } from 'react';

interface ISelectProps {
  name: string;
  form : any;
  options: { label: string; value: string }[];
  onChange?: any ;
}

const Select = ({ name, options, form, onChange}:ISelectProps) => {
  const [inputValue, setInputValue] = useState(form.fields[name].widget.value ?? "");
  const fieldError = form.errors?.[name];

  const handleChange =(event: React.ChangeEvent<HTMLSelectElement>) => {
  const value = event.target.value;
  setInputValue(value);
  if(onChange){
    onChange(event)
  }
  }

  return (
    <div>
      <select
        name={name}
        value={inputValue}
        onChange={handleChange}
        className={`p-3 w-full border border-neutral-300 rounded text-neutral-400 text-xs focus:border-base-content ${
          fieldError
            ? "hover:border-error"
            : "hover:border-base-content hover:border-opacity-40"
        } ${fieldError ? "border-error" : "border-neutral-300"}`}
      >
        <option disabled value="">
          Select {name}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="min-h-5">
        <p className="text-error text-xs">{fieldError}</p>
      </div>
    </div>
  );
};
export default Select