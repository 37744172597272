import * as React from "react";
import type { SVGProps } from "react";
const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.747 18.443H19"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.78 1.795a2.245 2.245 0 0 1 3.116-.303l1.733 1.347a2.14 2.14 0 0 1 .72 2.984c-.034.054-9.537 11.942-9.537 11.942a1.7 1.7 0 0 1-1.31.632l-3.638.046-.82-3.47c-.115-.489 0-1.001.316-1.396z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m9.02 4.001 5.453 4.187"
    />
  </svg>
);
export default SvgPencil;
