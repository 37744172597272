import * as React from "react";
import type { SVGProps } from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7349 12.135C20.8799 12.135 22.6249 13.8713 22.6249 16.005C22.6249 18.1388 20.8799 19.875 18.7349 19.875C16.5886 19.875 14.8424 18.1388 14.8424 16.005C14.8424 13.8713 16.5886 12.135 18.7349 12.135ZM18.7349 14.01C17.6224 14.01 16.7174 14.905 16.7174 16.005C16.7174 17.1063 17.6224 18 18.7349 18C19.8461 18 20.7499 17.1063 20.7499 16.005C20.7499 14.905 19.8461 14.01 18.7349 14.01ZM9.60038 15.116C10.1179 15.116 10.5379 15.536 10.5379 16.0535C10.5379 16.571 10.1179 16.991 9.60038 16.991H1.72412C1.20662 16.991 0.786625 16.571 0.786625 16.0535C0.786625 15.536 1.20662 15.116 1.72412 15.116H9.60038ZM4.64125 0C6.7875 0 8.5325 1.7375 8.5325 3.87125C8.5325 6.005 6.7875 7.74 4.64125 7.74C2.49625 7.74 0.75 6.005 0.75 3.87125C0.75 1.7375 2.49625 0 4.64125 0ZM4.64125 1.875C3.53 1.875 2.625 2.77 2.625 3.87125C2.625 4.97125 3.53 5.865 4.64125 5.865C5.75375 5.865 6.6575 4.97125 6.6575 3.87125C6.6575 2.77 5.75375 1.875 4.64125 1.875ZM20.9889 3.0005C21.5064 3.0005 21.9264 3.4205 21.9264 3.938C21.9264 4.4555 21.5064 4.8755 20.9889 4.8755H13.1139C12.5964 4.8755 12.1764 4.4555 12.1764 3.938C12.1764 3.4205 12.5964 3.0005 13.1139 3.0005H20.9889Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFilter;
