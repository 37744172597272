import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogleFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fill="#fff" />
    <path
      fill="currentColor"
      d="M7.945 11.455A7.89 7.89 0 0 1 15 7.105c2.128 0 3.915.783 5.282 2.057l-2.264 2.264c-.819-.782-1.86-1.181-3.018-1.181-2.057 0-3.797 1.39-4.417 3.255a4.7 4.7 0 0 0-.248 1.5c0 .521.09 1.026.248 1.5.62 1.866 2.36 3.255 4.417 3.255 1.062 0 1.966-.28 2.673-.754a3.63 3.63 0 0 0 1.576-2.383H15v-3.053h7.435c.093.516.144 1.054.144 1.614 0 2.405-.86 4.43-2.354 5.803-1.306 1.206-3.094 1.913-5.225 1.913a7.892 7.892 0 0 1-7.055-11.44"
    />
  </svg>
);
export default SvgGoogleFooter;
