import * as React from "react";
import type { SVGProps } from "react";
const SvgAddUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 23"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M1 18.896c0-.528.167-.917.462-1.24.316-.346.82-.66 1.551-.919 1.475-.522 3.583-.718 5.987-.718 2.415 0 4.522.202 5.992.73.728.261 1.232.578 1.546.926.295.326.462.718.462 1.248 0 .527-.167.916-.462 1.24-.316.345-.82.66-1.552.918-1.475.523-3.582.719-5.986.719-2.415 0-4.522-.203-5.992-.73-.728-.262-1.232-.58-1.546-.927-.295-.326-.462-.718-.462-1.247ZM13.955 6.033c0 2.808-2.214 5.032-4.955 5.032S4.045 8.841 4.045 6.033 6.259 1 9 1s4.955 2.225 4.955 5.033Z"
    />
  </svg>
);
export default SvgAddUser;
