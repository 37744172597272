import * as React from "react";
import type { SVGProps } from "react";
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#A2A2A2"
      d="M19.17 3.714a.73.73 0 0 1 1.035 0 .73.73 0 0 1 0 1.044L18.429 6.55c1.415 1.3 2.615 3.051 3.513 5.158a.75.75 0 0 1 0 .581c-2.089 4.903-5.806 7.837-9.943 7.837h-.01c-1.883 0-3.688-.62-5.279-1.753l-1.893 1.91a.72.72 0 0 1-.517.217.7.7 0 0 1-.517-.217.736.736 0 0 1-.088-.925l.03-.04 14.43-14.56c.02-.02.04-.04.05-.06.019-.02.038-.039.048-.058zm-7.169.171c1.396 0 2.752.335 4 .965l-3.258 3.288A4.5 4.5 0 0 0 12 8.07c-2.156 0-3.903 1.763-3.903 3.938 0 .257.03.513.069.749l-3.61 3.642c-.976-1.142-1.825-2.52-2.498-4.105a.75.75 0 0 1 0-.58c2.088-4.904 5.805-7.828 9.933-7.828zM15.22 9.79l-1.064 1.073c.176.334.273.728.273 1.142 0 1.348-1.093 2.451-2.43 2.451-.41 0-.8-.098-1.131-.275l-1.064 1.073c.625.423 1.386.679 2.196.679 2.146 0 3.893-1.762 3.893-3.928 0-.817-.254-1.585-.673-2.215"
    />
  </svg>
);
export default SvgHide;
