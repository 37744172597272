import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import AccordionComponent from "@client/components/AccordionComponent";
import PriceDetails from "@client/components/PriceDetails";
import ShippingAddress from "@client/components/ShippingAddress";
import OrderSummary from "@client/components/OrderSummary";
import { number } from "prop-types";
import ButtonComponent from "@client/components/ButtonComponent";
import Loader from "@client/components/Loader";
import {
  CreateOrder,
  DecrementCartQuantity,
  IncrementCartQuantity,
} from "@client/apis/Api";
import getDiscountedPercentage from "@client/utils";

export default (props: templates.CheckoutPage) => {
  const { user } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const [cartdata, setcartdata] = useState<any>(
    props.checked_cartitems.map((item) => ({
      id: item.pk,
      product_id: item.product.pk,
      name: item.product.name,
      price: item.product.price,
      images: item.product.images.length > 0 ? item.product.images : [],
      count: item.quantity,
      is_checked: item.is_checked,
      is_dynamic : item?.product?.attributes[0]?.base?.is_dynamic,
      // dynamic_value : item?.product?.attributes[0]?.dynamic_values[0]?.value,
      // dynamic_image_value : item?.product?.attributes[0]?.dynamic_image_value[0]?.value,
      // dynamic_product_label : item?.product?.attributes[0]?.base?.label,
      attributes: item?.product?.attributes,
    }))
  );

  const handleRadioChange = (option: string) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    totalCartPrice();
  }, [cartdata]);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const [pricedata, setPriceData] = useState({
    total_price: 0,
    item_count: 0,
    discount: 0,
  });

  const defaultAddress = props.addresses.find(address => address.is_default);
  const [selectedOption, setSelectedOption] = useState<string | null>(defaultAddress ? defaultAddress.pk : null);

  const totalCartPrice = () => {
    let tp = 0;
    let ic = 0;
    cartdata.map((item: any) => {
      ic++;
      tp += item.price * item.count;
    });
    let d = getDiscountedPercentage(user, tp) ;
    d = tp * d;
    setPriceData({
      total_price: tp,
      item_count: ic,
      discount: d,
    });
    return {
      total_price: tp,
      item_count: ic,
      discount: d,
    };
  };

  const handleDecrement = async (id: number, item_count: ReactNode) => {
    if (typeof item_count === "number" && item_count > 1) {
      try {
        const response = await DecrementCartQuantity(id);
        setcartdata((prevCounts: any) =>
          prevCounts.map((item: any) =>
            item.id === id
              ? { ...item, count: Math.max(item.count - 1, 0) }
              : item
          )
        );
      } catch (error) {
        console.error("erorr", error);
      }
    } else {
      alert("Item count is already one, no need to decrement further.");
    }
  };

  const handleIncrement = async (id: number) => {
    try {
      const response = await IncrementCartQuantity(id);
      setcartdata((prevCounts: any) =>
        prevCounts.map((item: any) =>
          item.id === id ? { ...item, count: item.count + 1 } : item
        )
      );
    } catch (error) {
      console.error("erorr", error);
    }
  };

  const handlePayment = async (e: any) => {
    if (cartdata.length === 0) {
      window.location.href = "/products/";
    } else if (selectedOption === null) {
      e.preventDefault();
      alert("Please select an Address");
    } else {
      const success_url = window.location.origin + "/success/";

      const data = {
        cartdata: cartdata,
        selected_address: selectedOption,
      };

      setIsLoading(true);
      try {
        const response = await CreateOrder(data);
        if (response.status === 201) {
          setIsLoading(false);
          const orderData = response.data;
          const razorpayOptions = {
            key: orderData.key_id,
            amount: Number(orderData.payment.amount),
            currency: "INR",
            order_id: orderData.payment.id,
            // name: "Grocery Store",
            // description: "Payment for Order #12345",
            // image: "http://example.com/your_logo",
            callback_url: success_url,
            theme: {
              color: "#3CB815",
            },
          };
          const rzp1 = new (window as any).Razorpay(razorpayOptions);
          rzp1.open();
          e.preventDefault();
        } else {
          setIsLoading(false);
          alert("Something happened. Try Again!");
          window.location.href = window.location.origin + "/checkout/";
        }
      } catch (error) {
        setIsLoading(false);
        console.error("error", error);
      }
    }
  };

  return (
    <Scaffold title="Home">
      {isLoading ? <Loader /> : <></>}
      <NavBar />
      {cartdata.length !== 0 ? (
        <>
          <div className="contianer mx-auto  mt-8 md:px-10   ">
            <div className="text-2xl lg:text-3xl font-bold text-primary pl-4 md:pl-7 lg:pl-20 ">
              Checkout
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 px-0 lg:px-14 gap-3 ">
              <div className="md:col-span-8  overflow-y-auto  h-[420px] p-5 ">
                <AccordionComponent
                  title="Shipping Address"
                  defaultChecked={true}
                >
                  <ShippingAddress
                    addresses={props.addresses}
                    selectedOption={selectedOption}
                    handleRadioChange={handleRadioChange}
                  />
                </AccordionComponent>
                <div className="mt-8"></div>
                <AccordionComponent title="Order Summary">
                  <OrderSummary
                    cartData={cartdata}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    isCheckout={true}
                  />
                </AccordionComponent>
              </div>

              <div className="md:col-span-4 ">
                <PriceDetails
                  onClick={handlePayment}
                  discount={pricedata.discount}
                  price={pricedata.total_price}
                  total={pricedata.total_price - pricedata.discount}
                  numberOfItem={pricedata.item_count}
                  showButton={false}
                  isCheckout={true}
                />
              </div>
            </div>
          </div>
          <div className="px-10 md:mt-20">
            {" "}
            <Footer />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col h-screen items-center justify-center gap-y-4 md:gap-y-8">
            <p className="font-extrabold text-xl text-center text-base-content md:text-4xl ">
              Add items to checkout!!!!
            </p>
            <ButtonComponent
              buttonText="Shop Now"
              coloredButton={true}
              onClick={handlePayment}
            />
          </div>
          <Footer />
        </>
      )}
    </Scaffold>
  );
};
