import React, { useContext } from "react";
import {  Context } from "@reactivated";
import FeatureBoundary from "@client/containers/FeatureBoundary";
interface LogoProps {
 className?:string
}
const Logo = ( props: LogoProps ) => {
  // TODO @roshna the useContext is already called inside
  // the FeatureBoundary calling it outside doesn't make sense
  // what can we do about this????

  const { feature } = useContext(Context);
  const flag = "user.site.logo"
  const setting = feature[flag]?.setting
  
  return (
    <>
      {setting === "1" ? (
        <FeatureBoundary flag={flag}>
          <a href="/">
            <img
              src={feature[flag]?.value}
              alt="logo "
              className={`h-12 ${props.className}`}
            />
          </a>
        </FeatureBoundary>
      ) : (
        <a href="/">
          <img
            src={"/assets/logo-reg.svg"}
            alt="logo "
            className={`h-12 ${props.className}`}
          />
        </a>
      )}
    </>
  );
};

export default Logo;
