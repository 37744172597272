import React, { MouseEventHandler } from "react";
interface OfferCardProps {
  buttonText: string;
  image: string;
  heading: string;
  description1: string;
  description2?: string;
  bgcolor: string;
  bgButtonColor: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const OfferCards = (props: OfferCardProps) => {
  return (
    <div className="cursor-pointer hover:shadow-lg rounded-lg">
      <div
        className={`flex md:h-[260px]   md:scale-90 lg:scale-100 ${props.bgcolor} rounded-xl justify-between  `}
      >
        <div className="flex flex-col items-start  md:pl-2 lg:px-10">
          <button
            className={`mt-2 md:mt-5 scale-75 md:scale-100  md:w-[120px] normal-case text-sm p-3 rounded-lg  ${props.bgButtonColor} text-base-100 font-poppins font-medium border-none `}
          >
            {props.heading}
          </button>
          <div className="text-base-100 mt-2 md:mt-5 font-poppins text-xl md:text-2xl font-semibold capitalize pl-3 md:pl-0">
            {props.description1}
          </div>
          <span className="text-base-100 font-poppins text-xs md:text-sm font-sm capitalize pl-3 md:pl-0">
            {props.description2}
          </span>
          <button
            className="btn border-none text-secondary mb-2 mt-2 md:mt-5 md:mb-5 text-sm font-bold scale-75 md:scale-100 w-[140px] px-8 flex-shrink-0 rounded-full bg-base-100 hover:bg-base-100 hover:shadow- capitalize"
            onClick={props.onClick}
          >
            {props.buttonText}
          </button>
        </div>
        <div className="flex justify-between items-center ">
          <img
            src={props.image}
            className="object-cover md: lg:block lg:max-w-full h-[180px] md:h-[220px] rounded-lg"
            alt="Offer Image"
          />
        </div>
      </div>
    </div>
  );
};

export default OfferCards;
