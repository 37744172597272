import React from "react";
import ProductCard from "@client/components/ProductCard";
import { ArrowRight } from "@client/assets/icons_svg";
import FeatureBoundary from "./FeatureBoundary";

interface IProduct {
  //   children: React.ReactNode;
  products: any[];
}

const RelatedProducts = ({ products }: IProduct) => {
  return (
    <FeatureBoundary flag="core.home.featuredProducts">
      {/* Related Products */}
      <div>
        <div className="  flex flex-row items-center sm:mb-6 md:mb-12 my-5">
          <p className="text-base-content font-semibold text-lg md:text-3xl w-5/6 md:w-10/12">
            You may be interested
          </p>
          <div className="w-2/6 md:w-2/12 ">
            <a href={`/products/`} className="">
              <p className="flex flex-row  float-end text-[10px] md:text-base font-medium text-primary">
                View all
                <ArrowRight className="  text-primary w-6 h-6" />
              </p>
            </a>
          </div>
        </div>
      </div>
      {/* product cards */}
      {products.length !== 0 ? (
        <div className="flex mt-1 md:mt-0 w-full justify-center p-1">
          <div
            // className="grid md:ml-0 grid-cols-2 sm:grid justify-items-center min-[430px]:grid-cols-3 max-[450px]:grid-cols-2
            //             sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-10 gap-x-12
            //             md:gap-[4.95rem] md:grid-cols-3 "
            className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-10"
          >
            {products.map((product) => (
              <ProductCard
                category={product.category.name}
                pk={product.id}
                price={product.price}
                productName={product.name}
                image={product.images.length > 0 ? product.images[0].image : ""}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex  h-32 items-center justify-center">
          <p className="text-sm text-base-content font-semibold md:text-lg">
            No products available to show
          </p>
        </div>
      )}
    </FeatureBoundary>
  );
};

export default RelatedProducts;
