import React, { useContext } from "react";
import { Context } from "@reactivated";

interface IFeatureBoundary {
  children: React.ReactNode;
  flag: string;
}

const FeatureBoundary = ({ children, flag }: IFeatureBoundary) => {
  const { feature } = useContext(Context);

  return (
    <>
      {/* if the key doesn't exist it will be undefined and not equal 1 */}
      {feature[flag] && feature[flag].setting === '1'
        ? children
        : ""}
    </>
  );
};

export default FeatureBoundary;
