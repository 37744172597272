import * as React from "react";
import type { SVGProps } from "react";
const SvgVector = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 18"
    {...props}
  >
    <path
      fill="#253D4E"
      d="M18.571 10.286H11.43v6.428c0 .341-.15.668-.419.91A1.5 1.5 0 0 1 10 18c-.379 0-.742-.136-1.01-.377a1.22 1.22 0 0 1-.419-.909v-6.428H1.43c-.38 0-.743-.136-1.01-.377A1.22 1.22 0 0 1 0 9c0-.341.15-.668.418-.91a1.5 1.5 0 0 1 1.01-.376h7.143V1.286c0-.341.15-.668.419-.91A1.5 1.5 0 0 1 10 0c.379 0 .742.135 1.01.377.268.24.419.568.419.909v6.428h7.142c.38 0 .743.136 1.01.377.268.241.419.568.419.909s-.15.668-.418.91a1.5 1.5 0 0 1-1.01.376"
    />
  </svg>
);
export default SvgVector;
