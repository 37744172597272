import * as React from "react";
import type { SVGProps } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.575 7.718s-.543 6.735-.858 9.572c-.15 1.355-.987 2.149-2.358 2.174-2.61.047-5.221.05-7.83-.005-1.318-.027-2.141-.831-2.288-2.162-.317-2.863-.857-9.58-.857-9.58M17.958 4.489H1M14.69 4.489a1.65 1.65 0 0 1-1.614-1.324l-.243-1.216A1.28 1.28 0 0 0 11.596 1H7.363a1.28 1.28 0 0 0-1.237.949l-.243 1.216a1.65 1.65 0 0 1-1.615 1.324"
    />
  </svg>
);
export default SvgDelete;
