import * as React from "react";
import type { SVGProps } from "react";
const SvgRupee = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 28"
    {...props}
  >
    <path
      fill="#253D4E"
      d="M20.309 6.222h-2.821c-.543 0-1.01-.356-1.217-.857-.399-.962.22-2.254 1.26-2.254h2.778c.448 0 .878-.164 1.196-.456.317-.291.495-.687.495-1.1 0-.412-.178-.808-.495-1.1A1.77 1.77 0 0 0 20.309 0H1.704A1.77 1.77 0 0 0 .508.456a1.5 1.5 0 0 0-.495 1.1c0 .412.178.808.495 1.1a1.77 1.77 0 0 0 1.196.455h5.92a6.3 6.3 0 0 1 3.152.848c1.254.732.446 2.263-1.006 2.263H1.704a1.77 1.77 0 0 0-1.196.456 1.5 1.5 0 0 0-.495 1.1c0 .412.178.808.495 1.1a1.77 1.77 0 0 0 1.196.455h7.877c2.082 0 3.613 2.031 1.988 3.331-1.071.857-2.441 1.33-3.86 1.336H1.704l-.035-.003a5 5 0 0 0-.402-.009.7.7 0 0 0-.267.06q-.101.045-.192.107a1.2 1.2 0 0 0-.365.272 1 1 0 0 0-.075.102 2 2 0 0 0-.107.146q-.047.07-.054.156a1 1 0 0 1-.096.362.95.95 0 0 0-.1.394 3 3 0 0 0-.007.427.4.4 0 0 0 .036.137q.024.052.053.1c.051.088.07.157.126.242.027.042.099.092.133.129l10.096 10.834c.292.313.707.507 1.154.54.447.031.89-.101 1.231-.37.341-.268.552-.65.587-1.06a1.48 1.48 0 0 0-.4-1.133L6.9 18.757c-.578-.63-.13-1.646.724-1.646 2.327.009 4.574-.785 6.296-2.225a8.7 8.7 0 0 0 1.98-2.355c.951-1.668 2.489-3.198 4.409-3.198.448 0 .878-.164 1.196-.455S22 8.19 22 7.778s-.178-.808-.495-1.1a1.77 1.77 0 0 0-1.196-.456"
    />
  </svg>
);
export default SvgRupee;
