import React from "react";
import ImageIcons from "@client/constants/index";
import FeatureBoundary from "@client/containers/FeatureBoundary";
import RightMainarrow from "@client/assets/icons_svg/RightMainarrow";
const AboutUs = () => {
  const ImageConstants = ImageIcons.AboutUs;
  return (
    <FeatureBoundary flag="core.home.aboutUs">
      <div className="container  py-10 pb-10 mx-auto  md:px-24 lg:px-40 mt-16 mb-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 lg:grid-cols-12 gap-12 justify-items-center items-center bg-base-100">
        <div className="col-span-5">
          <img src={ImageConstants.MainContentImage} alt="vegetables" />
        </div>
        <div className="col-span-7">
          <div className="text-primary uppercase font-poppins text-xl md:text-[28px] font-semibold">
            About us
          </div>
          <div className="my-4 text-base-content  font-poppins text-3xl  md:text-3xl lg:text-4xl font-bold leading-tight">
            We Believe In Working With Accredited Farmers
          </div>
          <div className="text-neutral-600 leading-trim  font-poppins text-base font-normal leading-40 ">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo modi
            qui, earum architecto animi officiis ducimus, est omnis quas illum a
            minus dolor ad eaque similique provident vel esse tenetur.
          </div>
          <div className="flex justify-items-center justify-between items-center">
            <button className="w-40 relative bg-primary pr-8 p-3 text-xs  text-primary-content rounded-full">
              Learn More
              <div className=" absolute rounded-full p-2 top-1 right-1  bg-base-100 flex items-center justify-center">
                <RightMainarrow className=" text-base-content w-4 h-4" />
              </div>
            </button>
            <div className=" w-14 h-14">
              <img
                src={ImageConstants.SideImage}
                alt="leaf"
                className="object-scale-down"
              />
            </div>
          </div>
        </div>
      </div>
    </FeatureBoundary>
  );
};

export default AboutUs;
