import React, { useCallback, useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";



export default (props: templates.HelloWorld) => {
  const { user } = useContext(Context)


  return (
    <Scaffold title="Home">
      <h1>hello {props.name}</h1>
      {user.name}
      <img src="/static/648-400x300.jpg" alt="" />
    </Scaffold>
  )
}
