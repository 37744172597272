import React, { ChangeEvent } from "react";

interface ICategoryFilterField {
  pk: string;
  name: string;
  existingCategoryIds: string[];
  level:Number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CategoryFilterField = (props: ICategoryFilterField) => {
  return (
    <>
      <div key={props.pk} className={`flex items-center mt-4 `}>
        <input
          checked={props.existingCategoryIds.includes(props.pk) ? true : false}
          type="checkbox"
          value={props.pk}
          onChange={props.onChange}
          className={`${
            props.level === 0 ? `` : props.level === 1 ? `ml-5` : `ml-10`
          } w-4 h-4  border-primary rounded checkbox checkbox-primary`}
        />

        <label
          htmlFor="default-checkbox"
          className="ms-2 text-sm font-normal text-base-content "
        >
          {props.name}
        </label>
      </div>
    </>
  );
};

export default CategoryFilterField;
