import React, { useCallback, useContext, useState } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";

export default (props: templates.ThemeTest) => {
  const { user } = useContext(Context);
  const [selectedTheme, setSelectedTheme] = useState("codelynks");

  const handleThemeChange = useCallback((theme: any) => {
    setSelectedTheme(theme);
  }, []);
  return (
    <Scaffold title="theme-test">
      <div className="m-4" data-theme={selectedTheme}>
        <div className="flex gap-4 mb-4">
          <button
            className="px-4 py-2 btn"
            onClick={() => handleThemeChange("codelynks")}
          >
            Codelynks Theme
          </button>
          <button
            className="px-4 py-2 btn"
            onClick={() => handleThemeChange("vcreate")}
          >
            Vcreate Theme
          </button>
          {/* Add more buttons for additional themes */}
        </div>
        <h3 className="text-2xl font-bold border-b-2 border-b-black my-4 capitalize">
          brand colors
        </h3>
        <div className="grid grid-cols-4 min-h-24">
          <div className="bg-primary">
            <b className="text-primary-content font-bold">primary</b>
          </div>
          <div className="bg-secondary">
            <b className="text-secondary-content font-bold">secondary</b>
          </div>
          <div className="bg-accent">
            <b className="text-accent-content font-bold">accent</b>
          </div>
        </div>

        <h3 className="text-2xl font-bold border-b-2 border-b-black my-4 capitalize">
          state colors
        </h3>
        <div className="grid grid-cols-4 min-h-24">
          <div className="bg-success">success</div>
          <div className="bg-info">info</div>
          <div className="bg-warning">warning</div>
          <div className="bg-error">error</div>
        </div>

        <h3 className="text-2xl font-bold border-b-2 border-b-black my-4 capitalize">
          neutral colors
        </h3>
        <div className="grid grid-cols-4 min-h-24">
          <div>
            <div className="bg-neutral-200">neutral-200</div>
            <div className="bg-neutral-300">neutral-300</div>
            <div className="bg-neutral-400">neutral-400</div>
            <div className="bg-neutral-500">neutral-500</div>
            <div className="bg-neutral-600">neutral-600</div>
            <div className="bg-neutral-700">neutral-700</div>
            <div className="bg-neutral">neutral</div>
          </div>
          <div>
            <div className="bg-base">base</div>
            <div className="bg-base-100">Base100</div>
            <div className="bg-base-200">Base200</div>
            <div className="bg-base-300">base 300</div>
            <div className="bg-base-content">Base content</div>
          </div>
          <div className="bg-accent-radio">radioaccent</div>
          <div className="bg-menucontent">menucontent</div>

          <div className="mt-10 flex gap-3">
            <input type="checkbox" className="checkbox checkbox-primary " />

            <input type="radio" className="radio radio-primary" />
            <input
              type="checkbox"
              defaultChecked
              className="checkbox checkbox-secondary"
            />
          </div>
        </div>
      </div>
    </Scaffold>
  );
};
