import React, { useEffect, useState } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import RelatedProducts from "@client/containers/RelatedProducts";
import { ImageViewer } from "@client/components/ImageViewer";
import { ProductDetailPanel } from "@client/components/ProductDetailPanel";

export default (props: templates.ProductDetail) => {
  
  return (
    <Scaffold title="Home">
      <NavBar />
      <div className="md:container md:mx-auto">
        <div className="p-3 md:px-20 lg:px-28 ">
          <div className="flex flex-col flex-wrap md:flex-row">
            <ImageViewer images={props.product.images} />
            <ProductDetailPanel
              product={props.product}
              attributes={props.attributes}
              dynamic_attribute={props.dynamic_attribute}
            />
          </div>
          <div className="divider mt-0 mb-4 md:my-12"></div>
          <RelatedProducts products={props.related_products} />
        </div>
        <Footer />
      </div>
    </Scaffold>
  );
};
