import React, { useState } from "react";
import Input from "./Input";
import { CSRFToken } from "@reactivated";
import PhoneInput from "./PhoneInput";
import EmailInput from "./EmailInput";
import RadioInput from "./RadioInput";
import ImageIcons from "@client/constants/index";
import { Pencil } from "@client/assets/icons_svg";

interface IGenderMappingProps {
  [key: number]: string;
}

const GenderMapping: IGenderMappingProps = {
  0: "Male",
  1: "Female",
  2: "Others",
};

interface IPersonalInformationProps {
  profile: any;
}

const PersonalInformation = (props: IPersonalInformationProps) => {
  const [phoneError, setPhoneError] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const EditUser = () => {
    setIsEditable(true);
  };
  const onCloseModal = () => {
    setIsEditable(false);
  };


  return (
    <div className="px-3 py-8 md:p-10 md:pt-7 shadow-square-shadow">
      <div className="">
        <div className="flex justify-between ">
          <div className="text-sm md:text-lg font-medium text-neutral">
            Personal Information
          </div>
          <button onClick={EditUser}>
            {/* TODO @roshna why did we use w-5 instead of font size?? */}
            <Pencil className="w-5 h-5 hover:text-primary" />
          </button>
        </div>
        <div className="border border-primary my-5"></div>
        {isEditable ? (
          <form method="POST">
            <CSRFToken />
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-2  gap-x-5">
              <div className="flex flex-col">
                <Input
                  name="first_name"
                  placeholder="Name"
                  type="text"
                  form={props.profile.form}
                />
              </div>

              <div className="flex flex-col">
                <PhoneInput
                  name="phone_number"
                  placeholder="Mobile Number"
                  form={props.profile.form}
                  ErrorProp={setPhoneError}
                />
              </div>
              <div className="flex flex-col">
                <EmailInput
                  name="email"
                  placeholder="Email Address"
                  form={props.profile.form}
                />
              </div>

              <div className="text-xs md:text-sm text-base-content">
                <label className="">Gender</label>
                <div className="flex space-x-1 md:space-x-3 my-1">
                  <RadioInput
                    name="gender"
                    options={[
                      { value: "0", label: "Male" },
                      { value: "1", label: "Female" },
                      { value: "2", label: "Others" },
                    ]}
                    form={props.profile.form}
                  />
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-2">
                  <button
                    className={`w-full font-medium h-10 md:h-15 px-4 py-2 text-primary-content  text-sm md:text-base text-Base bg-primary hover:shadow-md ${
                      phoneError ? "opacity-50" : ""
                    }`}
                    type="submit"
                    disabled={phoneError}
                  >
                    Save
                  </button>
                  <button
                    className="w-full font-medium h-10 md:h-15 px-4 py-2  text-sm md:text-base text-primary bg-base-100 hover:shadow border border-primary justify-center"
                    type="button"
                    onClick={onCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-2">
            <ItemDisplay
              name={"Name"}
              val={props.profile.form.fields.first_name.widget.value}
            />
            <ItemDisplay
              name={"Mobile Number"}
              val={props.profile.form.fields.phone_number.widget.value ? `+91 ${props.profile.form.fields.phone_number.widget.value}` : '-'}
            />
            <ItemDisplay
              name={"Email Address"}
              val={props.profile.form.fields.email.widget.value}
            />
            <ItemDisplay
              name={"Gender"}
              val={
                GenderMapping[
                  props.profile.form.fields.gender.widget
                    .value as keyof typeof GenderMapping
                ]
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ItemDisplay = (props: { name: string; val: string }) => {
  return (
    <div>
      <div className="font-medium text-xs text-neutral-600">{props.name}</div>
      <div className="text-base-content text-base md:text-md font-medium">
        {props.val}
      </div>
    </div>
  );
};
export default PersonalInformation;
