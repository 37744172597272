import React, { useState, useEffect } from "react";

interface IErrorInfo {
    message: string;
    code: string;
  }
  
  interface IPhoneInputProps {
    name: string;
    placeholder: string;
    errors?: IErrorInfo[];
    type?: string;
    form?:any;
    ErrorProp?: any;
  }

const PhoneInput = ({ name, placeholder, type, ErrorProp, form}: IPhoneInputProps) => {
  const [inputValue, setInputValue] = useState(form.fields[name].widget.value ?? "");
  const [fieldError, setFieldError] = useState(form.errors?.[name]);

  useEffect(() => {
    if (typeof ErrorProp === 'function') {
      ErrorProp(fieldError);
    }
  }, [fieldError]);
  

  const handleChange = (event:any) => {
    const value = event.target.value;
    setInputValue(value);
    if (value){
        if (!/^[6-9][0-9]{0,9}$/.test(value)) {
            setFieldError('Invalid phone number.');
        }else{
            setFieldError(form.errors?.[name] || "");
        }
    } else{
        setFieldError(form.errors?.[name] || "");
    }
  };
  
  return (
    <div>
      <input
        value={inputValue}
        type={type}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        className={`px-4 py-2 w-full h-12 text-xs text-base-content border rounded focus:border-base-content focus:outline-none ${fieldError ? 'hover:border-error' : 'hover:border-base-content hover:border-opacity-40'} ${fieldError ? 'border-error' : 'border-neutral-300'}`}
      />
      <div className="flex min-h-5">
        <p className="text-error text-xs">{fieldError}</p>
      </div>
    </div>
  );
};
export default PhoneInput;