import React, { useState } from "react";
import ImageIcons from "@client/constants/index";

export function ImageViewer({ images }: { images: any[]; }) {
  const ImageConstants = ImageIcons.ProductDetailsPage;

  const [selectedImage, setSelectedImage] = useState(
    images.length > 0
      ? images[0].image?.file
      : ImageConstants.DefaultProductImg
  );

  return (
    <div className="w-full md:w-1/2 inline-grid items-start  p-0 md:p-4">
      <div className="flex flex-col items-center justify-center overflow-hidden lg:w-auto md:flex-row">
        {/* image list */}
        <div className="carousel lg:carousel-vertical p-1  h-72 w-auto lg:w-1/6  md:order-first space-y-5  lg:block">
          <>
            {images.length > 0 ? (
              images.map((image: any) => (
                <>
                  <div
                    key={image.image.file}
                    className={`carousel-item md:aspect-square w-full  mx-auto lg:border items-center grid  ${selectedImage === image.image.file
                        ? "lg:border-base-content border-opacity-55"
                        : "lg:border-none"}`}
                    onClick={() => setSelectedImage(image.image.file)}
                  >
                    <img
                      src={image.image.file}
                      alt="i"
                      className=" mx-auto  h-[200px] lg:h-auto object-scale-down" />
                  </div>
                </>
              ))
            ) : (
              <>
                <div
                  key={selectedImage}
                  className={`carousel-item md:aspect-square w-full  mx-auto lg:border items-center grid border-base-content border-opacity-55`}
                  onClick={() => setSelectedImage(selectedImage)}
                >
                  <img
                    src={`${selectedImage}`}
                    alt="qa"
                    className="object-fill mx-auto" />
                </div>
              </>
            )}
          </>
        </div>
        {/* image card */}
        <div className="w-5/6 order-1 mx-auto px-5 hidden lg:block  ">
          <img
            src={selectedImage}
            alt=""
            className="mx-auto w-full object-scale-down lg:h-[300px] " />
        </div>
      </div>
    </div>
  );
}
