import React, { useCallback, useContext } from "react";
import { templates, Context,CSRFToken} from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import PasswordInput from "@client/components/PasswordInput";
import SideNavbar from "@client/components/SideNav";



export default (props: templates.ChangePassword) => {

  return (
    <Scaffold title="Change Password">
      <NavBar />

      <div className="mx-auto container ">
        <div className="grid grid-cols-12 mt-16 md:px-16 relative min-h-[500px]">
          <div className="bg-base-100 mt-10 md:mt-16  absolute top-0  left-0 border border-t-8 border-t-primary rounded border-base-200">
            <SideNavbar />
          </div>
          <div className="md:mt-16 mt-10 ml-10 md:ml-40 lg:ml-48 md:p-10 p-3 shadow-square-shadow rounded grid col-span-12 md:col-span-12 lg:col-span-7">
            <div>
              <div className="font-medium text-lg text-base-content">
                Change Password
              </div>
              <div className="border-primary mt-3 border"></div>
            </div>
            <form className="mt-9" method="POST">
              <CSRFToken />
              <PasswordInput
                label="password"
                name="current_password"
                placeholder="Current Password"
                type="password"
                form={props.form}
              />
              <PasswordInput
                label="password"
                name="password"
                placeholder="Password"
                type="password"
                form={props.form}
              />
              <PasswordInput
                label="password"
                name="confirm_password"
                placeholder="Confirm Password"
                type="password"
                form={props.form}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
                <button
                  className="w-full font-medium h-15 px-4 py-2 md:text-base text-sm text-Base  text-primary-content bg-primary hover:shadow-md"
                  type="submit"
                >
                  Save
                </button>
                <a
                  className="w-full font-medium h-15 px-4 py-2 md:text-base text-sm text-primary bg-Base hover:shadow border border-primary flex items-center justify-center cursor-pointer"
                  href="/account/"
                >
                  Cancel
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="lg:px-20 xl:px-24  hidden lg:block ">
        <Footer />
      </div>
    </Scaffold>
  );
}