import React from "react";
import { CategoryFilterField } from "./CategoryFilterField";

interface ICategoryFilter {
    children: React.ReactNode;
}


export const CategoryFilterComponent = ({ children }: ICategoryFilter) => {
    return (
      <>
        <div className="flex items-center space-x-2">
          <h3 className="text-base-content text-xl font-bold">Categories</h3>
        </div>

        <div className="p-2">{children}</div>
      </>
    );
}

export default CategoryFilterComponent