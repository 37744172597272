import { IData } from "@client/hooks/UseCart";
import { Context } from "@reactivated";
import React, { useContext, useEffect, useState } from "react";

interface IAttributesPanel {
  attributes: any[];
  dynamic_attribute: any[];
  dynamicValue: IData;
  setDynamicValue: any;
}

function getDynamicAttrValue(attributes: any[], cart?: any) {
  if (!attributes || attributes.length === 0) {
    return;
  }

  let d = attributes?.filter((item) => item?.is_dynamic);
  // TODO @ivin this filtering should come form the backend it's not working right now
  d = d[0]?.attributes[0]?.dynamic_values?.filter((item) =>
    cart?.cartitems?.some((cart_item) => cart_item?.pk === item?.cartitem?.id)
  );
  return d[0]?.value;
}
function getDynamicAttrImage(attributes: any[], cart?: any) {
  if (!attributes || attributes.length === 0) {
    return;
  }

  let d = attributes.filter((item) => item.is_dynamic);
  // TODO @ivin this filtering should come form the backend it's not working right now
  d = d[0]?.attributes[0]?.dynamic_image_value.filter((item) =>
    cart?.cartitems?.some((cart_item) => cart_item?.pk === item?.cartitem?.id)
  );
  return d[0]?.value;
}

export function AttributesPanel({
  attributes,
  dynamic_attribute,
  dynamicValue,
  setDynamicValue,
}: IAttributesPanel) {
  const { cart } = useContext(Context);

  // console.log("cart", cart);
  // console.log("attribute", attributes);
  // console.log("dy attr", dynamic_attribute);

  useEffect(() => {
    setDynamicValue({
      ...dynamicValue,
      value: getDynamicAttrValue(dynamic_attribute, cart),
      image: getDynamicAttrImage(dynamic_attribute, cart),
    });
  }, []);

  const handleInputChange = (event: any, baseAttr: any) => {
    handleImageUpload(event, baseAttr);
  };

  const handleImageUpload = async (event: any, baseAttr: any) => {
    const file = event.target.files[0];
    console.log("baseAttr", baseAttr);

    const id = baseAttr?.pk;

    const formData = new FormData();
    formData.append("image_file", file);

    const response = await fetch(
      `/api/dynamic-image-add/${id}/setDynamicImageValue/`,
      {
        method: "POST",
        body: formData,
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log("comment respoe", data.data.value);

      setDynamicValue({
        ...dynamicValue,
        baseAttr: baseAttr,
        image: data.data.value,
      });
    } else {
      console.error("Error uploading image:", response.statusText);
    }
  };

  return (
    <div className="mt-4">
      {attributes?.map((baseAttr: any) => (
        <div className="grid  items-center border-primary ">
          <p className="text-neutral-500 text-xs md:text-sm">
            {baseAttr.label}
          </p>

          <div className="flex flex-wrap gap-2 pt-1 pb-3">
            {baseAttr?.attributes
              ?.sort((a: any, b: any) => a.sort_order - b.sort_order)
              ?.map((attribute: any) => (
                <AttrSelect attribute={attribute}>{baseAttr.label}</AttrSelect>
              ))}
          </div>
        </div>
      ))}

      {dynamic_attribute?.map((baseAttr: any) => (
        <div className="grid  items-center border-primary ">
          <p className="text-neutral-500 text-xs md:text-sm">
            {baseAttr.label}
          </p>
          <div className="flex flex-wrap gap-2 pt-1 pb-3">
            {baseAttr?.input_type === 1 ? (
              <>
                {dynamicValue.image === undefined ? (
                  <input
                    type="file"
                    onChange={(event) => handleInputChange(event, baseAttr)}
                    className="file-input file-input-bordered  w-full max-w-xs "
                  />
                ) : (
                  <div className="avatar">
                    <div className="w-40 rounded">
                      <img src={dynamicValue.image} alt="" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex text-xs border-primary md:text-base p-2 border items-center rounded-lg w-full">
                <input
                  type="text"
                  className="border border-none focus:outline-none w-full"
                  value={dynamicValue.value}
                  onChange={(event) =>
                    setDynamicValue({
                      ...dynamicValue,
                      baseAttr: baseAttr,
                      value: event.target.value,
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

interface IAttrSelect {
  children: string;
  attribute: any;
  is_selected?: boolean;
}

function AttrSelect({ children, attribute }: IAttrSelect) {
  const selectVariant = async (e: any, attribute: any) => {
    window.location.href = `/product/${attribute.ppk}/`;
  };

  const is_selected = attribute.is_selected ? "border-primary" : "";
  const is_active = attribute.is_active ? "" : "hidden";
  // console.log("=attribute=>", attribute);

  return (
    <div
      id={attribute.pk}
      className={`relative border p-2 flex items-center gap-2 rounded-lg min-w-16 ${is_selected} ${is_active}`}
    >
      <input
        type="radio"
        name={children}
        id={attribute.pk}
        checked={attribute.is_selected}
        className="absolute w-3/4 cursor-pointer h-full opacity-0"
        onClick={(event) => selectVariant(event, attribute)}
      />
      <p>{attribute.value}</p>
    </div>
  );
}
