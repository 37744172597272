import React from "react";

const Loader = () => {
  return (
    <div
      className={`fixed inset-0 h-full bg-black bg-opacity-50 z-50 flex justify-center items-center transition duration-200 ease-in`}
    >
      <span className="loading loading-spinner text-primary w-20"></span>
    </div>
  );
};

export default Loader;
