import React, { ChangeEvent } from "react";
import CategoryFilterField from "../components/CategoryFilterField";

interface IfilterComponent {
    // categories: { pk: string; name: string }[];
    // categoryIDs: string[];
    // categoryCheckboxHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    children: React.ReactNode;
}

export const FilterComponent = (props: IfilterComponent) => {
    return (
        <>
            {props.children}
        </>
    )
}

export default FilterComponent