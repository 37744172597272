import React, { useState } from "react";
import Input, { IInputProps } from "./Input";
import ImageIcons from "@client/constants/index";

interface IPasswordInputProps extends IInputProps {
  label: string;
}

const PasswordInput = ({ label, ...rest }: IPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const ImageConstants = ImageIcons.PasswordInput;

  return (
    <>
      <div className="relative">
        <Input {...rest} type={showPassword ? "text" : "password"} />
        <img
          className="absolute top-3 right-3"
          src={showPassword ? ImageConstants.PasswordHide : ImageConstants.PasswordShow}
          onClick={() => setShowPassword(!showPassword)}
          alt="Password visibility"
        />
      </div>
    </>
  );
};

export default PasswordInput;
