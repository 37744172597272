import * as React from "react";
import type { SVGProps } from "react";
const SvgTwitterFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fill="#fff" />
    <path
      fill="currentColor"
      d="m15.864 15.733 3.997-4.68h-.948l-3.47 4.063-2.772-4.063H9.474l4.192 6.145-4.192 4.907h.947l3.665-4.291 2.928 4.291h3.197zm-1.298 1.519-.424-.612-3.38-4.869h1.455l2.727 3.93.425.611 3.545 5.108H17.46z"
    />
  </svg>
);
export default SvgTwitterFooter;
