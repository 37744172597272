import React from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { AuthBox } from "@client/containers/AuthBox";

export default (props: templates.ForgotPasswordDone) => {
  return (
    <Scaffold title="forgot-password">

      <AuthBox>
        <div className="text-base-content flex flex-col md:shrink-0 mt-6 mb-7 md:mb:0 px-2 items-center">
          
          <p className="text-3xl text-base-content font-semibold pt-12 pb-2">
            Check your email
          </p>
          <p className="text-base-content pb-16">We sent you a password reset link</p>

          <div className="pb-48">
            <a href="/account/login/" className="text-sm text-gray-400">
              Back to login
            </a>
          </div>
        </div>
      </AuthBox>

    </Scaffold>
  );
};