import React, { useState } from "react";
import { CSRFToken, templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import PasswordInput from "@client/components/PasswordInput";
import EmailInput from "@client/components/EmailInput";
import ImageIcons from "@client/constants/index";
import { AuthBox } from "@client/containers/AuthBox";
import FeatureBoundary from "@client/containers/FeatureBoundary";

export default (props: templates.UserLogin) => {
  const [emailError, setEmailError] = useState(false);
  const images = ImageIcons.UserLogin;
  const checkURL = (e: any) => {
    e.preventDefault();
    window.location.href.indexOf("?next=") === -1
      ? (window.location.href = e.target.href)
      : (window.location.href =
          e.target.href +
          window.location.href.slice(window.location.href.indexOf("?next=")));
  };

  return (
    <Scaffold title="login">
      <AuthBox>
        <div className="flex flex-col md:shrink-0 py-10">
          <p className="text-2xl sm:text-3xl font-semibold text-base-content">
            Hey there !
          </p>
          <p className="text-2xl sm:text-3xl font-semibold text-base-content">
            Welcome back
          </p>
        </div>
        <form className="" method="POST">
          <CSRFToken />
          <EmailInput
            name="email"
            placeholder="Email address"
            form={props.form}
            onEmailChange={setEmailError}
          />

          <PasswordInput
            label="password"
            name="password"
            placeholder="Password"
            form={props.form}
          />
          <div className="flex justify-between mb-6">
            <div className="flex items-center ">
              <input
                name="remember_me"
                id="remember_me"
                type="checkbox"
                className="w-4 h-4 bg-neutral-300  border-neutral-300  rounded  accent-accent-radio focus:accent-accent-radio"
              />
              <label className="ms-2 text-xs md:text-sm lg:text-sm   text-neutral-500 ">
                Remember me
              </label>
            </div>
            <div className=" cursor-pointer">
              <a
                href="/account/password-reset/"
                className="text-xs md:text-sm lg:text-sm   cursor-pointer text-primary float-end"
              >
                Forgot Password ?
              </a>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 mt-12">
            <button
              className={`w-full font-medium h-12 px-4 py-2 mr-5 text-primary-content bg-primary rounded hover:shadow-md ${
                emailError ? "opacity-50" : ""
              }`}
              type="submit"
              disabled={emailError}
            >
              Login
            </button>
            <FeatureBoundary flag="core.login.google">
              <button
                className="w-full h-12 px-2 py-1 font-semibold text-xs text-secondary bg-base-100 hover:shadow border border-base-300 rounded flex items-center justify-center"
                type="button"
              >
                <img src={images.GoogleIcon} alt="logo" />
                Sign in with Google
              </button>
            </FeatureBoundary>
          </div>

          <p className="text-xs sm:text-sm  text-center text-base-content py-4 mb-4">
            Don’t have an Account ?{" "}
            <a
              href="/account/registration/"
              className=" text-sm cursor-pointer text-primary "
              onClick={(event) => checkURL(event)}
            >
              Sign Up
            </a>
          </p>
        </form>
      </AuthBox>
    </Scaffold>
  );
};