import React, { useContext, useEffect, useState } from "react";
import RatingComponent from "./RatingComponent";
import { useCart } from "@client/hooks/UseCart";
import ImageIcons from "@client/constants/index";
import { Buy, Heart, HeartFill, ViewIcon } from "@client/assets/icons_svg";
import FeatureBoundary from "@client/containers/FeatureBoundary";

interface IProductCardProps {
  pk: string;
  discount?: number;
  category: string;
  productName: string;
  actualPrice?: number;
  price: number;
  image?: string;
  isLiked?: boolean;
  is_dynamic?: boolean;
}
const ProductCard = (props: IProductCardProps) => {
  const [isWishListed, setIswishListed] = useState(false);
  useEffect(() => {
    if (props.isLiked) {
      setIswishListed(true);
    }
  }, []);
  const AddToWishList = () => {
    setIswishListed(!isWishListed);
  };

  const addToUserCart = useCart();
  const ImageConstants = ImageIcons.ProductCard;

  return (
    <>
      <div className="group card p-0 bg-base-100 rounded-md border border-solid border-1 border-base-200 hover:shadow-xl ">
        <a href={`/product/${props.pk}/`} className="">
          <div className="card-actions justify-start">
            <div className="relative">
              <div className="bg-warning min-w-14 h-[28px] absolute p-[5px] mt-3 z-20">
                <p className="text-xs font-bold text-base-100">
                  -{props.discount ? props.discount : 25}%
                </p>
              </div>
              <div
                className="w-0 h-0 mt-3
            border-t-[14px] border-t-transparent
             border-r-[14px] border-r-transparent
                          border-b-[14px] border-b-transparent
                            absolute left-[40px] z-20"
              ></div>
            </div>
          </div>

          <figure className="relative rounded-t-md p-1">
            {props.image !== "" ? (
              <img
                src={props?.image?.file}
                alt=""
                className="min-h-[12em] rounded-md object-cover"
              />
            ) : (
              <img
              className="rounded-md w-[90px]"
                src={ImageConstants.DefaultProductImg}
                alt=""
              />
              // <span className="inline-block h-  flex-auto cursor-wait bg-current align-middle text-base text-neutral-700 opacity-50 dark:text-neutral-50"></span>
            )}
          </figure>

          <div className="card-body p-[20px] ">
            <p className="text-[8px] sm:text-[10px] font-medium m-0 uppercase text-neutral-500">
              {props.category}
            </p>
            <h2 className=" card-title font-semibold text-[12px] sm:text-[17px] mt-[-10px] text-base-content ">
              <div className=" max-w-40  truncate"> {props.productName}</div>
            </h2>

            <div className="grid grid-cols-2 items-center justify-start  gap-y-2 ">
              <RatingComponent coloredCount={4} nonColoredCount={1} />

              <a
                href="#"
                className=" text-[8px] sm:text-[12px] font-normal text-base-content no-underline  ml-1"
              >
                (73)
              </a>
            </div>

            <p className="text-lg text-base-content font-bold mt-[-5px]">
              <span>&#8377;</span>
              {props.price}{" "}
              <span className="line-through text-sm ml-2 text-neutral-500">
                <span>&#8377;</span>
                {props.price * 1.25}
              </span>
            </p>
          </div>
        </a>
        <div className="flex flex-col gap-2 absolute top-3 right-4  opacity-0 pointer-events-none transition-opacity duration-300 group-hover:opacity-100 group-hover:pointer-events-auto">
          {props.is_dynamic ? null : (
            <div
              className=" p-1 rounded-[50px] bg-base-100 cursor-pointer"
              onClick={() => addToUserCart(props.pk, false, false)}
            >
              <Buy className="w-6 h-6 text-base-content" />
            </div>
          )}
          <div className=" p-1 rounded-[50px] bg-base-100 cursor-pointer">
            <a href={`/product/${props.pk}/`} className="">
              <ViewIcon className="w-6 h-6 text-base-content" />
            </a>
          </div>
          <FeatureBoundary flag="core.nav.wishlist">
          <div
            className=" p-1 rounded-[50px] bg-base-100 cursor-pointer"
            onClick={AddToWishList}
          >
          {isWishListed ? (
                        <HeartFill className="w-6 h-6 text-base-content" />
                      ) : (
                        <Heart className="w-6 h-6 text-base-content" />
                      )}
          </div>
          </FeatureBoundary>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
