import React, { useState } from "react";
import RatingComponent from "./RatingComponent";
import parse from "html-react-parser";
import ImageIcons from "@client/constants/index";
import { useCart, IData } from "@client/hooks/UseCart";
import { AttributesPanel } from "./AttributesPanel";

interface IProductDetailPanel {
  product: any;
  attributes: any;
  dynamic_attribute: any;
}


export function ProductDetailPanel(props: IProductDetailPanel) {
  let addToUserCart = useCart();

  const [dynamicValue, setDynamicValue] = useState<IData>({
    baseAttr: undefined,
    value: '',
    image: '',
    cartitem: 0,

  });
  // const handleAddToCart = () => {
  //   if (props.dynamic_attribute && props.dynamic_attribute.length > 0) {
  //     props.dynamic_attribute.map((item: any, index) => {
  //       if (item.input_type==1 && !dynamicValue.image) {
  //         alert("Upload an image 🖼️ below, and we'll 3D print your imagination into reality🚀.");
  //         return;
  //       }
  //       if (item.input_type==0 && !dynamicValue.value) {
  //         alert('Type your text below, and watch us transform it into a pencil masterpiece! ✏️✨');
  //         return;
  //       }
  //       addToUserCart(props.product.id, false, true, dynamicValue);
  //     });
  //   }else{
  //     addToUserCart(props.product.id, false, true, dynamicValue);
  //   }
  // };
  // const handleBuyNow = () => {
  //   if (props.dynamic_attribute && props.dynamic_attribute.length > 0) {
  //     props.dynamic_attribute.map((item: any, index) => {
  //       if (item.input_type==1 && !dynamicValue.image) {
  //         alert("Upload an image 🖼️ below, and we'll 3D print your imagination into reality🚀.");
  //         return;
  //       }
  //       if (item.input_type==0 && !dynamicValue.value) {
  //         alert('Type your text below, and watch us transform it into a pencil masterpiece! ✏️✨');
  //         return;
  //       }
  //       addToUserCart(props.product.id, true, false)
  //     });
  //   }else{
  //     addToUserCart(props.product.id, true, false)
  //   }
  // };
const handleAction = (isBuyNow:boolean) => {
  const { dynamic_attribute, product } = props;
  if (dynamic_attribute && dynamic_attribute.length > 0) {
    for (const item of dynamic_attribute) {
      if (item.input_type === 1 && !dynamicValue.image) {
        alert("Upload an image 🖼️ below, and we'll 3D print your imagination into reality🚀.");
        return;
      }
      if (item.input_type === 0 && !dynamicValue.value) {
        alert('Type your text below, and watch us transform it into a pencil masterpiece! ✏️✨');
        return;
      }
    }
  }
  addToUserCart(product.id, isBuyNow, !isBuyNow, dynamicValue);
};

  const imageConstant = ImageIcons.ProductDetailsPage;

  return (
    <div className="shrink-0 order-3 w-full md:w-1/2  p-4">
      <p className="mt-2 font-normal text-[10px] md:text-sm text-base-content">
        {props.product.category.name}
      </p>
      <p className="mb-3 mt-2 text-xl font-semibold md:text-3xl text-base-content">
        {props.product.name}
      </p>
      <div className="flex gap-3 lg:gap-8 md:items-center mb-3">
        <p className="text-primary text-xs lg:text-lg ">In stock</p>
        <div className="flex flex-row items-center gap-1">
          <div className="scale-75 md:scale-100">
            <RatingComponent coloredCount={4} nonColoredCount={1} />
          </div>
          <p className="text-base-content lg:text-lg text-[10px] truncate">
            ({props.product.description.length} Reviews)
          </p>
        </div>
      </div>
      <div className="text-wrap md:overflow-y-auto md:h-32 xl:h-auto overscroll-y-none scrollbar-hide">
        <p className=" text-xs md:text-base font-normal text-base-content">
          {parse(props.product.description)}
        </p>
      </div>

      {/* attributes starts */}
      <AttributesPanel
        attributes={props.attributes}
        dynamic_attribute={props.dynamic_attribute}
        dynamicValue={dynamicValue}
        setDynamicValue={setDynamicValue}

        // input={input}
        // setInput={setInput}
        // radiofn={selectvariant}
        // productAttributesIDArray={productAttributesIDArray}
        // handleInputChange={handleInputChange}
      />

      {/* price */}
      <div className="my-4 flex items-baseline">
        <img
          src={imageConstant.Rupee}
          alt=""
          className="w-4 h-4 md:w-6 md:h-6"
        />
        <p className="text-xl font-bold md:text-3xl text-base-content">
          {props.product.price}
        </p>
      </div>

      {/* call to action */}
      <div className=" md:mt-1 flex ">
        {props.product.in_cart ? (
          <OutlineButton onClick={() => (window.location.href = "/cart/")}>
            Go to cart
          </OutlineButton>
        ) : (
          <OutlineButton onClick={() => handleAction(false)}>
          Add to cart
        </OutlineButton>
        )}
<FilledButton onClick={() => handleAction(true)}>
  Buy now
</FilledButton>
      </div>
    </div>
  );
}

interface IButton extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function OutlineButton({ children, onClick }: IButton) {
  return (
    <div
      className={
        "text-xs text-center py-2 items-center  md:text-base font-semibold  rounded-sm border border-primary text-primary w-1/2 cursor-pointer block mx-1"
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function FilledButton({ children, onClick }: IButton) {
  return (
    <div
      className={
        "text-xs text-center py-2 items-center  md:text-base font-semibold  rounded-sm border border-primary bg-primary text-primary-content w-1/2 cursor-pointer block mx-1"
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
}
