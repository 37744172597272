export const codelynks = {
  Navbar: {
    LogoIcon: "/assets/logo-reg.svg",
    HeartIcon: "/assets/icons/Heart.svg",
    SearchIcon: "/assets/icons/Search.svg",
    BuyIcon: "/assets/icons/Buy.svg",
    ProfileIcon: "/assets/icons/profile.svg",
    ViewProfile: "/assets/icons/Add-User.svg",
    Logout: "/assets/icons/Logout.svg",
    Login: "/assets/icons/Login.svg",
    CancelSearch: "/assets/icons/cancel.svg",
    AllCategoriesIcon: "/assets/icons/all.svg",
    DropDown: "/assets/dropdown.png",
  },
  SideNavbar: {
    Location: "/assets/icons/Location.svg",
    ProfileIcon: "/assets/icons/Profile.svg",
    BuyIcon: "/assets/icons/BuyNav.svg",
    ChangePasswordIcon: "/assets/icons/Lock.svg",
  },
  BannerComponent: {
    BannerImage: "/assets/logo/banner.png",
    Youtube: "/assets/icons/youtube.svg",
    Linkedin: "/assets/icons/linkedin.svg",
    Twitter: "/assets/icons/twitter.svg",
    Facebook: "/assets/icons/fb.svg",
    FollowText: "/assets/icons/follow.svg",
    RightArrow: "/assets/icons/rightarrow.svg",
    BannerCircle: "/assets/circleOuter.svg",
  },
  CategoryCard: {
    CardHoverButton: "/assets/icons/hoverButton.svg",
    DefaultCategoryImg: "/assets/defaultImage.png",
  },
  AboutUs: {
    MainContentImage: "/assets/veg.svg",
    SideImage: "/assets/leaf.svg",
    ArrowIcon: "/assets/icons/rightarrow.svg",
  },
  Footer: {
    LogoIcon: "/assets/logo-reg.svg",
    Facebook: "/assets/icons/fb-footer.svg",
    YouTube: "/assets/icons/yb-footer.svg",
    Google: "/assets/icons/google-footer.svg",
    Twitter: "/assets/icons/twitter-footer.svg",
  },
  NewsLetter: {
    MainContentImage: "/assets/basket.svg",
    SubContent: "/assets/capsicum.svg",
  },
  OrderHistory: {
    RightArrow: "/assets/Arrow - Right.svg",
  },
  OrderSummary: {
    DefaultImg: "/assets/defaultImage.png",
    Delete: "/assets/icons/Delete.svg",
  },
  PasswordInput: {
    PasswordShow: "/assets/icons/Show.svg",
    PasswordHide: "/assets/icons/Hide.svg",
  },
  PersonalInformation: {
    Edit: "/assets/icons/Edit.svg",
  },
  ProductCard: {
    AddToCart: "/assets/icons/Buy.svg",
    View: "/assets/icons/viewIcon.svg",
    AddToWishList: "/assets/icons/Heart.svg",
    WishListed: "/assets/icons/HeartFill.svg",
    DefaultProductImg: "/assets/defaultImage.png",
  },
  ProductDetailsPage: {
    Rupee: "/assets/icons/rupee.svg",
    DefaultProductImg: "/assets/defaultImage.png",
    RightArrow: "/assets/Arrow - Right.svg",
  },
  ShippingAddress: {
    AddIcon: "/assets/icons/add.svg",
  },
  Testimonial: {
    Quotes: "/assets/icons/quotes.svg",
    Profile: "/assets/icons/profile.svg",
  },
  Carousal: {
    LeftArrow: "/assets/icons/leftArrow.svg",
    RightArrow: "/assets/icons/rightArrow.svg",
  },

  CartPage: {
    EmptyCart: "/assets/Empty_cart.svg",
  },
  HomePage: {
    OfferImgOne: "/assets/delivaryman.svg",
    OfferImgTwo: "/assets/fruits.svg",
    Discount: "/assets/icons/discount.svg",
    Delivary: "/assets/icons/delivery.svg",
    Return: "/assets/icons/return-box.svg",
    RightArrow: "/assets/icons/Arrow-Right.svg",
  },
  PaymentFailure: {
    PaymentFailure: "/assets/payment_failure.svg",
  },

  ProductList: {
    FilterIcon: "/assets/icons/filter.svg",
    EmptyCart: "/assets/Empty_cart.svg",
  },
  SuccessPage: {
    PaymentSuccsess: "/assets/payment_success.svg",
  },
  UserAddressList: {
    Delete: "/assets/icons/Delete.svg",
    Edit: "/assets/icons/Edit.svg",
    Add: "/assets/icons/add.svg",
  },
  UserLogin: {
    LoginMainImage: "/assets/logo/banner3.png",
    Logo: "/assets/Grocery.svg",
    GoogleIcon: "/assets/Google.svg",
  },
};
