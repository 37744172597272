import React, { useCallback, useContext, useState } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import SideNavbar from "@client/components/SideNav";
import Footer from "@client/components/Footer";
import OrderHistory from "@client/components/OrderHistory";

export default (props: templates.OrderHistoryPage) => {
  const { user } = useContext(Context);

  const [orderData, setOrderData] = useState<any>(
    props.order_items.map((item) => ({
      id: item.pk,
      razorpay_order_id: item.razorpay_order_id,
      status: item.status,
      orderitems: item.orderitems,
      order_date: item.order_date,
      total_cost: item.total_cost,
    }))
  );

  return (
    <Scaffold title="Order history">
      <NavBar />
      <div className="mx-auto container">
        <div className="grid grid-cols-12 mt-16 md:pl-16 relative min-h-[500px]">
          <div className=" bg-base-100 mt-10 md:mt-16  absolute top-0  left-0 border border-t-8 border-t-primary rounded border-base-200">
            <SideNavbar />
          </div>
          <div className="mt-10 md:mt-16 col-span-12  ml-12 md:ml-40  ">
            <OrderHistory items={orderData} />
          </div>
        </div>
        <Footer />
      </div>
 
    </Scaffold>
  );
};
