import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/ui/carousel";

interface ICarousalProps {
  children: React.ReactNode[];
}

const Carousal = ({ children }: ICarousalProps) => {
  const wrappedChildren = children.map((item, index) => {
    // TODO @roshna can we remove the `py-8` here??
    return (
      <CarouselItem className="basis-1/2 md:basis-1/3  py-8 min-[1128px]:basis-1/4 ">
        {item}
      </CarouselItem>
    );
  });

  return (
    <div>
      <Carousel
        opts={{
          align: "center",
        }}
        className=" w-full "
      >
        <CarouselContent className="">{wrappedChildren}</CarouselContent>

        {/* TODO @roshna is the a reason for only one to have bg-base-100  */}
        <CarouselPrevious className="bg-base-100 hidden sm:block " />

        <CarouselNext className="bg-base-100 hidden sm:block"/>
      </Carousel>
    </div>
  );
};

export default Carousal;
