import React, { useContext } from "react";
import { Context } from "@reactivated";
import ImageIcons from "@client/constants/index";
import FeatureBoundary from "@client/containers/FeatureBoundary";
import {
  ArrowRight,
  Fb,
  Follow,
  Instagram,
  Linkedin,
  RightMainarrow,
  Twitter,
  Youtube,
} from "@client/assets/icons_svg";

const BannerComponent = () => {
  const ImageConstants = ImageIcons.BannerComponent;
  const { feature } = useContext(Context);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  items-center  lg:px-24">
      <div className="flex gap-12 p-0 md:p-4 lg:p-2 mt-8 md:mt-4 lg:mt-2 ">
        <div className="hidden md:flex   flex-col gap-4 mt-8">
          <FeatureBoundary flag="user.socials.youtube">
            <a href={feature["user.socials.youtube"]?.value}>
              <Youtube className="text-xl cursor-pointer" />
            </a>
          </FeatureBoundary>

          <FeatureBoundary flag="user.socials.linkedin">
            <a href={feature["user.socials.linkedin"]?.value}>
              <Linkedin className="text-lg cursor-pointer" />
            </a>
          </FeatureBoundary>

          <FeatureBoundary flag="user.socials.twitter">
            <a href={feature["user.socials.twitter"]?.value}>
              <Twitter className="text-xl cursor-pointer" />
            </a>
          </FeatureBoundary>

          <FeatureBoundary flag="user.socials.facebook">
            <a href={feature["user.socials.facebook"]?.value}>
              <Fb className="text-xl cursor-pointer" />
            </a>
          </FeatureBoundary>
          <FeatureBoundary flag="user.socials.instagram">
            <a href={feature["user.socials.instagram"]?.value}>
              <Instagram className="text-xl cursor-pointer hover:text-primary" />
            </a>
          </FeatureBoundary>

          <Follow />
        </div>
        <div className="flex flex-col justify-start">
          <div className="text-primary uppercase">Let's Create</div>
          <div className=" text-3xl  lg:text-5xl  font-bold text-secondary">
            Print your<br></br>
            <span className="text-primary"> Dreams, </span>
            Today

          </div>
          <div>
            <a href="/products/">
              <button className="w-40  relative bg-primary  flex items-center justify-items-center pl-8 p-3 text-xs mt-10 text-primary-content rounded-full">
                Shop Now
                {/* <img
                  src={ImageConstants.RightArrow}
                  className=" absolute rounded-full p-2 top-1 right-1  bg-base-100"
                /> */}
                <div className=" absolute rounded-full p-2 top-1 right-1  bg-base-100 flex items-center justify-center">
                  <RightMainarrow className=" text-base-content w-4 h-4" />
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="hidden md:block lg:block    items-center">
        <div className=" scale-0 md:scale-75 lg:scale-100  lg:p-20">
          <div className=" relative ">
            <img
              src={ImageConstants.BannerImage}
              className="w-full  hidden md:block "
            />
            <div className="absolute top-0 ">
              <img
                src={ImageConstants.BannerCircle}
                alt="circle"
                className="animate-circle hidden md:block relative "
              />

              <img
                src={ImageConstants.BannerCircle}
                alt="circle"
                className="animate-circle hidden md:block  absolute top-12  right-12  w-3/4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;