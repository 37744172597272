import React from "react";
import ImageIcons from "@client/constants/index";
import { HoverButton } from "@client/assets/icons_svg";

interface ICategoryCardProps {
  categoryImage: string;
  productCount: number;
  categoryName: string;
  categoryId: string;
  bgColor: string;
}

const CategoryCard = (props: ICategoryCardProps) => {
  const ImageConstants=ImageIcons.CategoryCard
  return (
    <div className="category-list  flex justify-center items-center card w-62">
      <div className="w-full">
        <a href={`/products/?category=${props.categoryId}`}>
          <div
            style={{ backgroundColor: props.bgColor }}
            className="group/card h-52 md:h-60  bg-ec-cream scale-90 hover:scale-100 md:scale-90
                                                   rounded-[10px] transition ease-in-out delay-150 hover:-translate-y-0.5 md:hover:scale-100 duration-300"
          >
            <div className="h-[8em] md:h-[8em] ">
              <figure className="px-2  pt-2 md:px-10 md:pt-5 h-[8em] md:h-[8em]">
                <img
                  src={
                    props.categoryImage
                      ? props?.categoryImage?.file
                      : ImageConstants.DefaultCategoryImg
                  }
                  alt=""
                  className="rounded-xl  object-scale-down"
                />
              </figure>
            </div>
            <div className="card-body items-center text-center px-0 pt-0 ">
              <h2 className="card-title text-base-content text-center capitalize text-base md:text-xl font-medium pt-[10px]">
                {props.categoryName}
              </h2>
              {props.productCount ? (
                <h4 className="text-xs md:text-sm text-base-content">
                  ( {props.productCount}items)
                </h4>
              ) : (
                <h4 className="text-xs md:text-sm text-base-content">
                  (No items)
                </h4>
              )}
              <div className="card-actions ">
                <div className="h-1  md:h-4 items-center ">
                  <button className="hidden md:mt-2 group-hover/card:block border-none hover:bg-primary btn btn-circle bg-primary transition duration-5000 ease-in-out ">
                    <HoverButton className="w-12 h-12 text-primary " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CategoryCard;
