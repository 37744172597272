import { current } from "@reduxjs/toolkit";
import * as React from "react";
import type { SVGProps } from "react";
const SvgBuy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3.438 4.062 2.6.45L7.24 18.854a2.25 2.25 0 0 0 2.25 2.066h13.637a2.25 2.25 0 0 0 2.23-1.933l1.187-8.197a1.677 1.677 0 0 0-1.419-1.899c-.08-.011-18.67-.017-18.67-.017M17.656 13.493h3.467"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.943 25.253a.68.68 0 1 1 0 1.36.68.68 0 0 1 0-1.36M23.043 25.253a.681.681 0 1 1 .001 1.362.681.681 0 0 1 0-1.362"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBuy;
