import * as React from "react";
import type { SVGProps } from "react";
const SvgBuyNav = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#253D4E"
      fillRule="evenodd"
      d="M7.543 19.952a1.296 1.296 0 0 1 0 2.589 1.296 1.296 0 0 1 0-2.59m11.28 0c.716 0 1.297.58 1.297 1.294s-.581 1.295-1.296 1.295a1.296 1.296 0 0 1 0-2.59M3.269 3.009l2.08.36c.335.06.59.337.619.677l.235 2.801h.873l.424.001h1.604l.38.001 1.77.001h.33l1.242.001h.291l1.092.001h.255l.952.001h.847l.196.001h.725l.166.001h.612l.14.001h.389l.12.001h.433l.097.001h.267l.08.001h.286l.063.001h.167l.05.001.17.001h.128l.026.001h.067l.018.001h.046l.012.001h.028l.008.001.02.001h.004l.013.001a2.08 2.08 0 0 1 1.38.82c.335.447.475.998.395 1.55l-.95 6.559a2.56 2.56 0 0 1-2.522 2.188H7.975a2.56 2.56 0 0 1-2.54-2.342L4.52 4.748l-1.507-.26A.75.75 0 0 1 2.4 3.62a.76.76 0 0 1 .867-.61m3.607 5.339h-.547l.603 7.172c.044.552.495.965 1.046.965h10.917c.52 0 .966-.387 1.04-.902l.95-6.56a.59.59 0 0 0-.112-.438.58.58 0 0 0-.388-.23h-.16l-.076.001-.566.001h-1.708l-1.52-.001h-1.136l-.297-.001h-.91l-.31-.001h-.628l-.317-.001-.958-.001h-.96l-.32-.001h-.636l-.315-.001h-.93l-.304-.001-1.176-.001zm10.413 2.196a.75.75 0 0 1 0 1.5h-2.773a.75.75 0 1 1 0-1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBuyNav;
