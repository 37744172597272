import React, { useState, useEffect } from "react";

interface IErrorInfo {
  message: string;
  code: string;
}

export interface IInputProps {
  name: string;
  placeholder: string;
  errors?: IErrorInfo[];
  type?: string;
  val?: string;
  form?:any;
}

const Input = ({ name, placeholder, type, errors, form }: IInputProps) => {
  const [inputValue, setInputValue] = useState(form?.fields?.[name].widget.value ?? "");
  const [fieldError, setFieldError] = useState(form?.errors?.[name]);

  const handleChange = (event:any) => {
  const value = event.target.value;

  if (name === 'first_name' || name==='name') {
    const re = /^[a-zA-Z\s]*$/;
    if (re.test(value)) {
      setInputValue(value);
    }
  } else {
    setInputValue(value);
  }
};

  return (
    <div>
      <input
        value={inputValue}
        onChange={handleChange}
        type={type}
        className={`px-4 py-2 w-full h-12 text-xs text-base-content border rounded focus:border-base-content focus:outline-none ${fieldError ? 'hover:border-error' : 'hover:border-base-content hover:border-opacity-40'} ${fieldError ? 'border-error' : 'border-neutral-300'}`}
        placeholder={placeholder}
        name={name}
      />
      <div className="flex min-h-5">
        <p className="text-error text-xs">{fieldError}</p>
      </div>
    </div>
  );
};
export default Input;