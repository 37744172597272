import * as React from "react";
import type { SVGProps } from "react";
const SvgQuotes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 34 26"
    {...props}
  >
    <path
      fill="currentColor"
      d="M26.4 25.8q-3.5 0-5.8-2.3-2.2-2.3-2.2-6.6 0-4.6 2.6-9.1 2.7-4.5 7.5-7l2.3 3.5q-5.2 3.6-6.2 9.1.9-.4 2.1-.4 2.8 0 4.6 1.8t1.8 4.6-1.9 4.6-4.8 1.8M8 25.8q-3.5 0-5.8-2.3Q0 21.2 0 16.9q0-4.6 2.6-9.1 2.7-4.5 7.5-7l2.3 3.5q-5.2 3.6-6.2 9.1.9-.4 2.1-.4 2.8 0 4.6 1.8t1.8 4.6-1.9 4.6T8 25.8"
    />
  </svg>
);
export default SvgQuotes;
