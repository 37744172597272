import React, { useEffect, useState } from "react";
import { CSRFToken } from "@reactivated";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import PhoneInput from "./PhoneInput";
import PinCodeInput from "./PinCodeInput";
import RadioInput from "./RadioInput";
import { getCityList } from "@client/apis/Api";

interface IAddressFormProps {
  form: any;
}
interface ICityProps {
  id: string;
  name: string;
}

const AddressForm = (props: IAddressFormProps) => {
  const [phoneError, setPhoneError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState(false);

  const [page, setPage] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get('page');
    setPage(page ? page : "");
  }, []);
  const handleCancel = () => {
    if (page === 'orders') {
      window.location.href = '/checkout/';
    }else{
      window.location.href = '/account/addresses/';
    }
  };

  const [cities, setCities] = useState<ICityProps[]>([]);
  const [selectedState, setSelectedState] = useState(props.form.fields.state.widget.value);

  useEffect(() => {
    if (selectedState) {
      getCities(selectedState);
    }
  }, [selectedState]);
  // api call
  const getCities = async (value:any) => {
    try {
      const response = await getCityList(value);
      setCities(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <div>
        <div className="font-medium text-lg text-base-content">
          Add New Address
        </div>
        <div className="border-primary mt-3 border"></div>
      </div>
      <form method="POST">
        <CSRFToken />
        <div className="mt-8 grid md:grid-cols-2  grid-cols-1 gap-x-3">
          <Input placeholder="Name" name="name" form={props.form} />
          <PhoneInput
            placeholder="10-digit mobile number"
            name="phone_number"
            form={props.form}
            ErrorProp={setPhoneError}
          />
          <PinCodeInput
            placeholder="Pincode"
            name="pin_code"
            form={props.form}
            ErrorProp={setPinCodeError}
          />
          <Input placeholder="Locality" name="locality" form={props.form} />
          <TextArea
            name="address"
            placeholder="Address (Area and Street)"
            form={props.form}
          />
          <Select
            name="state"
            form={props.form}
            options={props.form.fields.state.widget.optgroups
              ?.slice(1)
              .map((optgroup: any) => ({
                label: optgroup[1][0].label,
                value: optgroup[1][0].value,
              }))}
            onChange={(e: any) => setSelectedState(e.target.value)}
          />
          <Select
            name="city"
            form={props.form}
            options={cities.map((city) => ({
              label: city.name,
              value: city.id,
            }))}
          />
          <Input
            name="landmark"
            placeholder="Landmark (Optional)"
            form={props.form}
          />
          <PhoneInput
            name="optional_phn_num"
            placeholder="Alternate Phone (Optional)"
            form={props.form}
            ErrorProp={setPhoneError}
          />

          <div className="mt-2">
            <label className="text-sm text-base-content">Address type</label>
            <div className="flex space-x-4 mt-2">
              <RadioInput
                name="address_type"
                options={[
                  { value: "0", label: "Home" },
                  { value: "1", label: "Work" },
                ]}
                form={props.form}
              />
            </div>
          </div>
          <br />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
            <button
              className={`w-full font-medium h-15 px-4 py-2  text-base text-primary-content bg-primary hover:shadow-md  ${
                phoneError || pinCodeError ? "opacity-50" : ""
              }`}
              disabled={!!phoneError || !!pinCodeError}
              type="submit"
            >
              Save
            </button>
            <div
              className="w-full font-medium h-15 px-4 py-2 text-base text-primary bg-base-100 hover:shadow border border-primary flex items-center justify-center cursor-pointer"
              onClick={handleCancel}
            >
              Cancel
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddressForm;