import React, { useState } from "react";
import { CSRFToken } from "@reactivated";
import ImageIcons from "@client/constants/index";
import Logo from "@client/assets/icons_svg/Logo";

interface IAuthBoxProps {
  children: React.ReactNode;
}
export const AuthBox = ({ children }: IAuthBoxProps) => {

  const imageConstant = ImageIcons.UserLogin;

  return (
    <div className=" bg-base-200 h-screen flex flex-nowrap items-center ">
      <div className="w-1/12 md:w-2/6"></div>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 container mx-auto p-0 lg:w-10/12">
        <div className="lg:block lg:col-span-1 hidden">
          <img
            className="h-full w-full object-cover"
            src={imageConstant.LoginMainImage}
            alt="banner image"
          />
        </div>
        <div className="bg-base-100 text-base-200  col-span-1 px-3 sm:py-4 sm:px-6">
          <div className="md:shrink-0 flex justify-center items-center h-[100px] m-1">
           <Logo className="h-16"/>
          </div>
          {children}
        </div>
      </div>
      <div className="w-1/12 md:w-2/6"></div>
    </div>
  );
};