import React, { useCallback, useContext, useEffect, useState } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import PriceDetails from "@client/components/PriceDetails";
import OrderSummary from "@client/components/OrderSummary";
import Input from "@client/components/Input";
import ButtonComponent from "@client/components/ButtonComponent";
import {
  ClearCart,
  DecrementCartQuantity,
  IncrementCartQuantity,
  RemoveCartItem,
  checkoutWithSelection,
} from "@client/apis/Api";
import ImageIcons from "@client/constants/index";
import getDiscountedPercentage from "@client/utils";

export default (props: templates.CartPage) => {
  const { user } = useContext(Context);
  console.log('comment asd', user);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const handleToggleItem = async (itemId: any) => {
    try {
      const response = await checkoutWithSelection(itemId);
      response?.data.checked
        ? setSelectedItems((prevSelectedItems: any) => {
            return [...prevSelectedItems, itemId];
          })
        : setSelectedItems((prevSelectedItems: any) => {
            return prevSelectedItems.filter((ID: any) => ID !== itemId);
          });
    } catch (error) {
      console.error("error", error);
    }
  };

  const [cartdata, setcartdata] = useState<any>(
    props.cartitems.map((item) => ({
      id: item.pk,
      name: item.product.name,
      price: item.product.price,
      images: item.product.images.length > 0 ? item.product.images : [],
      count: item.quantity,
      is_checked: item.is_checked,
      is_dynamic: item?.product?.attributes[0]?.base?.is_dynamic,
      // dynamic_value: item?.product?.attributes[0]?.dynamic_values[0]?.value,
      // dynamic_image_value:item?.product?.attributes[0]?.dynamic_image_value[0]?.value,
      // dynamic_product_label: item?.product?.attributes[0]?.base?.label,
      attributes: item?.product?.attributes,
    }))
  );

  useEffect(() => {
    cartdata.map((item: any) => {
      item.is_checked &&
        setSelectedItems((prevselectedItems: any[]) => {
          return [...prevselectedItems, item.id];
        });
    });
  }, []);

  useEffect(() => {
    totalCartPrice();
  }, [cartdata, selectedItems]);

  const [pricedata, setPriceData] = useState({
    total_price: 0,
    item_count: 0,
    discount: 0,
  });

  const totalCartPrice = () => {
    let tp = 0;
    let ic = 0;
    let dataset;

    dataset = cartdata;

    dataset.map((item: any) => {
      if (selectedItems.includes(item.id)) {
        ic++;
        tp += item.price * item.count;
      }
    });
    let d = getDiscountedPercentage(user, tp) ;
    d = tp * d;
    setPriceData({
      total_price: tp,
      item_count: ic,
      discount: d,
    });
    return {
      total_price: tp,
      item_count: ic,
      discount: d,
    };
  };

  const removeCartItem = async (id: number) => {
    try {
      const response = await RemoveCartItem(id);
      if (response.status === 204) {
        const updatedCartItems = cartdata.filter((item: any) => item.id !== id);
        setcartdata(updatedCartItems);
        setSelectedItems((prevSelectedItems: any) => {
          if (prevSelectedItems.includes(id)) {
            return prevSelectedItems.filter((ID: any) => ID !== id);
          } else {
            return prevSelectedItems;
          }
        });
      } else {
        console.log("something happenend", response.statusText);
      }
    } catch (error: any) {
      console.error("erorr", error.message);
    }
  };

  const clearCart = async () => {
    try {
      const response = await ClearCart();
      if (response.status) {
        setcartdata([]);
        setSelectedItems([]);
        console.log("its gone");
      } else {
        console.log("something happenend", response.statusText);
      }
    } catch (error: any) {
      console.error("erorr", error.message);
    }
  };

  const handleIncrement = async (id: number) => {
    try {
      const response = await IncrementCartQuantity(id);
      setcartdata((prevCounts: any) =>
        prevCounts.map((item: any) =>
          item.id === id ? { ...item, count: item.count + 1 } : item
        )
      );
    } catch (error) {
      console.error("erorr", error);
    }
  };

  const handleDecrement = async (id: number) => {
    try {
      const response = await DecrementCartQuantity(id);
      if (response.data.quantity === 0) {
        await removeCartItem(id);
      } else {
        setcartdata((prevCounts: any) =>
          prevCounts.map((item: any) =>
            item.id === id
              ? { ...item, count: Math.max(item.count - 1, 0) }
              : item
          )
        );
      }
    } catch (error) {
      console.error("erorr", error);
    }
  };

  const handleCheckout = async (e: any) => {
    if (cartdata.length === 0) {
      window.location.href = "/";
    } else if (selectedItems.length === 0) {
      alert("You haven't selected any items to checkout.");
      e.preventDefault();
    } else {
      window.location.href = `/checkout/`;
    }
  };
  const ImageConstants = ImageIcons.CartPage;

  return (
    <Scaffold title="Home">
      <NavBar />
      {cartdata.length !== 0 ? (
        <div>
          <div className="pt-10 pb-3 px-8 min-[1128px]:px-28  max-[1023px]:px-12 lg:px-20  mb-3">
            <div className="font-bold text-2xl md:text-3xl  text-base-content">
              Shopping cart
            </div>
          </div>

          <div
            className="grid grid-cols-1 md:grid-cols-12 px-4 md:px-8
            min-[1128px]:px-24
           max-[1023px]:px-8 lg:px-16 gap-3 "
          >
            <div className="md:col-span-7 lg:col-span-8  pl-5 ">
              <div className="hidden lg:grid bg-base-200 p-3  grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 justify-center  items-center font-bold text-center justify-items-start">
                <div className="col-span-3 text-base-content text-base font-normal pl-12">
                  Product
                </div>

                <div className="pl-5 text-base-content text-base font-normal">
                  Quantity
                </div>
                <div className="pl-3 text-base-content text-base font-normal">
                  Price
                </div>
                <div></div>
              </div>
              <div className="">
                <OrderSummary
                  // cartData={user === "" ? localcart : cartdata}
                  cartData={cartdata}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  removeCartItem={removeCartItem}
                  selectedItems={selectedItems}
                  handleToggle={handleToggleItem}
                  hasSelectInput={true}
                />
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2   gap-5 mt-5">
                <div className="flex gap-3 items-baseline ">
                  <Input
                    name="coupon-code"
                    placeholder="Enter coupon code :"
                    type="text"
                    val=""
                  />
                  <ButtonComponent buttonText="Apply" coloredButton={true} />
                </div>

                <div className="">
                  <div className="float-start lg:float-end ">
                    <ButtonComponent
                      buttonText="Clear Cart"
                      coloredButton={false}
                      onClick={clearCart}
                    />
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="md:col-span-5 lg:col-span-4">
              <PriceDetails
                onClick={handleCheckout}
                discount={pricedata.discount}
                price={pricedata.total_price}
                total={pricedata.total_price - pricedata.discount}
                numberOfItem={pricedata.item_count}
                showButton={true}
                isCheckout={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center h-screen text-center gap-y-4 md:gap-y-6 ">
            <img
              src={ImageConstants.EmptyCart}
              alt=""
              className="w-24 h-24 md:w-32 md:h-32"
            />
            <div className="flex flex-col gap-y-1 md:gap-y-2">
              <p className="text-base-content text-base font-bold md:text-xl">
                Your Cart is Empty
              </p>
              <p className="text-base-content text-xs font-medium md:text-sm">
                Looks like you haven’t added anything to your cart yet
              </p>
            </div>
            <ButtonComponent
              buttonText="Shop Now"
              coloredButton={true}
              onClick={handleCheckout}
            />
          </div>
        </>
      )}
      <div className="md:px-10  ">
        <Footer />
      </div>
    </Scaffold>
  );
};
