import React from "react";
import Carousal from "@client/components/Carousal";
import ProductCard from "@client/components/ProductCard";
import { ArrowRight } from "@client/assets/icons_svg";
import FeatureBoundary from "./FeatureBoundary";

interface IProduct {
  //   children: React.ReactNode;
  products: any[];
}

const FeaturedProducts = (props: IProduct) => {
  return (
    <FeatureBoundary flag="core.home.featuredProducts">
      <div>
        <div className="grid items-center lg:px-28 mt-16 md:mb-16">
          <div className="flex justify-between  md:mb-7 items-center">
            <div className=" text-xl md:text-3xl font-bold text-base-content">
              Featured <span className="text-primary">Products</span>
            </div>
            <div>
              <a
                className="flex flex-row cursor-pointer items-center"
                href="/products/"
              >
                <div className="text-sm md:text-base font-medium text-primary	">
                  View all
                </div>
                <ArrowRight className="  text-primary w-6 h-6" />
              </a>
            </div>
          </div>
          <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 item-center justify-center gap-12 md:gap-12 lg:gap-12 ">
            <Carousal>
              {props.products.map((item: any) => {
                return (
                  <ProductCard
                    pk={item.id}
                    productName={item.name}
                    category={item.category.name}
                    price={item.price}
                    actualPrice={item.price}
                    image={item.images.length > 0 ? item.images[0].image : ``}
                    discount={10}
                    is_dynamic = {item?.attributes[0]?.base?.is_dynamic}
                  />
                );
              })}
            </Carousal>
          </div>
        </div>
      </div>
    </FeatureBoundary>
  );
};

export default FeaturedProducts;