import React, { useState } from "react";
import EmailInput from "./EmailInput";
import ImageIcons from "@client/constants/index";
import FeatureBoundary from "@client/containers/FeatureBoundary";

export const NewsLetter = () => {
  const [emailError, setEmailError] = useState(false);
    const ImageConstants = ImageIcons.NewsLetter;

  return (
    <FeatureBoundary flag="core.home.newsletter">
      <div className="  my-16  lg:px-28  ">
        <div className=" rounded-lg bg-base-300 pt-12 pb-12 md:pb-0 grid grid-cols-1 md:grid-cols-12 gap-6 items-center justify-items-center">
          <div className="col-auto md:col-span-8 ">
            <div className=" p-4 md:px-20">
              <div className="text-secondary text-2xl md:text-3xl lg:text-4xl font-bold pb-5 ">
                Stay home & get your daily needs from our shop
              </div>
              <div className="text-neutral-700 font-poppins text-xs md:text-base font-normal pb-5">
                Start Your Daily Shopping with &nbsp;{" "}
                <span className="text-primary font-poppins text-xs md:text-base font-normal ">
                  Grocery organic
                </span>
              </div>{" "}
              <form method="POST">
                <div className="relative md:w-[300px] lg:w-[370px]">
                  <EmailInput
                    name="subscription"
                    placeholder="Your email address"
                    onEmailChange={setEmailError}
                    className="px-7 sm:px-10 text-[10px] md:text-sm h-11 md:h-12 py-5  rounded-[50px] border-none"
                  />{" "}
                  <button
                    className={`p-[13px] text-[10px] md:text-sm absolute bg-primary normal-case top-[0px] items-center text-primary-content right-0  rounded-[50px] ${
                      emailError ? "opacity-50" : ""
                    }`}
                    type="submit"
                    disabled={emailError}
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>

            <img
              src={ImageConstants.SubContent}
              className="hidden md:block h-24 w-24"
            />
          </div>

          <div className="col-span-4 md:block hidden">
            <img
              src={ImageConstants.MainContentImage}
              className="hidden md:block "
            />
          </div>
        </div>
      </div>
    </FeatureBoundary>
  );
};

export default NewsLetter;
