import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.786 19.026a8.24 8.24 0 1 0 0-16.48 8.24 8.24 0 0 0 0 16.48M16.517 16.945l3.23 3.222"
    />
  </svg>
);
export default SvgSearch;
