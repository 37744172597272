import React, { ChangeEvent, useState } from "react";

interface IPriceFilterComponent {
  minPriceFromUrl?: number;
  onMinPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onMaxPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
  priceFilterHandler: () => void;
  priceFilterClearFunc: () => void;
  maxPriceFromUrl: number;
}

export const PriceFilterComponent = (props: IPriceFilterComponent) => {
  return (
    <>
      <div className="flex items-center space-x-2 mt-6">
        <h3 className=" text-xl text-nowrap font-bold text-base-content">
          Filter by Price
        </h3>
      </div>
      <div className=" pt-4">
        <div className="flex items-center">
          <div className="flex w-2/5 h-11 border-neutral-200 border-solid border-[1px] rounded-[5px] items-center justify-center">
            <input
              type="text"
              className="w-11/12 h-11/12 focus:outline-0 text-center text-base-content"
              placeholder={props.minPriceFromUrl !== 0 ? undefined : "Min"}
              value={
                props.minPriceFromUrl !== 0 || props.maxPriceFromUrl !== 0
                  ? props.minPriceFromUrl
                  : ""
              }
              onChange={(e) => props.onMinPriceChange(e)}
            />
          </div>
          <div className="divider">-</div>
          <div className="flex w-2/5 h-11  border-neutral-200 border-solid border-[1px] rounded-[5px] items-center justify-center">
            <input
              type="text"
              className="w-11/12 h-11/12 focus:outline-0 text-center text-base-content"
              placeholder={props.maxPriceFromUrl !== 0 ? undefined : "Max"}
              value={props.maxPriceFromUrl !== 0 ? props.maxPriceFromUrl : ""}
              onChange={(e) => props.onMaxPriceChange(e)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 items-center gap-x-3 ">
          <div
            className={`${
              props.maxPriceFromUrl && props.maxPriceFromUrl !== 0
                ? ``
                : `pointer-events-none opacity-40 `
            } mt-4 btn px-8 text-sm bg-base-100  text-primary  normal-case hover:bg-base-100 hover:shadow-lg hover:border-primary border-primary`}
            onClick={() => props.priceFilterClearFunc()}
          >
            Clear
          </div>
          <div
            className="mt-4 btn px-8 bg-primary border-primary text-primary-content text-sm
         hover:bg-primary hover:shadow-lg hover:border-primary 
            normal-case"
            onClick={() => props.priceFilterHandler()}
          >
            Filter
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceFilterComponent;
