import React from "react";
interface serviceCardProps {
  name: string;
  image: string;
  text: string;
}
const ServiceCard = (props: serviceCardProps) => {
  return (
    <div className=" bg-slate-100  rounded-lg w-72  lg:w-64">
      <div className="py-7  flex items-center justify-center gap-3">
        <div>
          <img src={props.image} />
        </div>
        <div>
          <div className="text-base-content font-medium text-base">
            {props.name}
          </div>
          <div className=" text-neutral-700 font-medium text-sm">
            {props.text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
