import React from "react";

import ProductCard from "@client/components/ProductCard";
import ImageIcons from "@client/constants/index";
import { ArrowRight } from "@client/assets/icons_svg";
import FeatureBoundary from "./FeatureBoundary";

interface IDealsShowcase {
  //   children: React.ReactNode;
  products: any[];
}

const DealsShowcase = (props: IDealsShowcase) => {
  return (
    <FeatureBoundary flag="core.home.dealsShowcase">
      <div>
        <div
          title="deals-of-week "
          className="md:bg-base-200 grid items-center lg:px-12  md:py-10"
        >
          <div className="grid grid-cols-1  md:grid-cols-3 items-center justify-center gap-6">
            <div className="flex justify-center text-xl md:text-2xl lg:text-3xl font-bold text-base-content">
              Deals <span className="ml-2 text-primary">of the Week</span>
            </div>

            <div className="flex items-center justify-center gap-2">
              <div className=" bg-base-100 rounded-md px-3 py-1">
                <div className="text-xl lg:text-2xl font-bold text-primary text-center">
                  6
                </div>
                <div className="text-xs md:text-sm font-medium	 text-base-content text-center">
                  Days
                </div>
              </div>
              <span className="text-base-content font-medium">:</span>
              <div className=" bg-base-100 rounded-md px-3 py-1 ">
                <div className="text-xl lg:text-2xl font-bold text-primary text-center">
                  37
                </div>
                <div className="text-xs md:text-sm font-medium	 text-base-content text-center">
                  Hours
                </div>
              </div>
              <span className="text-base-content font-medium">:</span>
              <div className=" bg-base-100 rounded-md px-3 py-1 ">
                <div className="text-xl md:text-2xl font-bold text-primary text-center">
                  6
                </div>
                <div className="text-xs md:text-sm font-medium	 text-base-content text-center">
                  Mins
                </div>
              </div>
              <span className="text-base-content font-medium">:</span>
              <div className=" bg-base-100 rounded-md px-3 py-1 ">
                <div className="text-xl md:text-2xl font-bold text-primary text-center">
                  6
                </div>
                <div className="text-xs md:text-sm font-medium	 text-base-content text-center">
                  Secs
                </div>
              </div>
            </div>

            <a
              className="flex justify-center cursor-pointer text-base-content "
              href="/products/"
            >
              <div className="text-sm md:text-base font-medium text-primary	">
                View all
              </div>
              <ArrowRight className="  text-primary w-6 h-6" />
            </a>
          </div>

          <div
            className="mt-10 grid grid-cols-2 justify-items-center  md:ml-0
                          min-[465px]:grid-cols-3 max-[450px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-10 md:gap-y-10 gap-x-[3.5rem] md:gap-x-2 lg:gap-y-12  "
          >
            {props.products.map((product: any) => (
              <>
                <div className=" ">
                  <ProductCard
                    category={product.category.name}
                    pk={product.pk}
                    price={product.price}
                    productName={product.name}
                    image={
                      product.images.length > 0 ? product.images[0].image : ""
                    }
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </FeatureBoundary>
  );
};

export default DealsShowcase;