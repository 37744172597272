import * as React from "react";
import type { SVGProps } from "react";
const SvgAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 23 22"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.548 1H1v7.5h7.548zM21.128 4.75c0-2.071-1.69-3.75-3.774-3.75S13.58 2.679 13.58 4.75s1.69 3.75 3.774 3.75 3.774-1.679 3.774-3.75M8.548 13.5H1V21h7.548zM21.128 13.5H13.58V21h7.548z"
    />
  </svg>
);
export default SvgAll;
