import React, { useEffect, useState } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import ProductCard from "@client/components/ProductCard";
import { CategoryFilterField } from "@client/components/CategoryFilterField";
import FilterComponent from "@client/containers/FilterComponent";
import { CategoryFilterComponent } from "@client/components/CategoryFilterComponent";
import PriceFilterComponent from "@client/components/PriceFilterComponent";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import ButtonComponent from "@client/components/ButtonComponent";
import ImageIcons from "@client/constants/index";
import { Filter } from "@client/assets/icons_svg";

export default (props: templates.ProductList) => {
  const [categoryIDs, setCategoryIDs] = useState<string[]>([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [apiEndpoint, setAPIEndpoint] = useState("");

  const [productData, setproductData] = useState<any>(
    props.products.map((item) => ({
      pk: item.id,
      category: item.category,
      name: item.name,
      price: item.price,
      images: item.images.length > 0 ? item.images : [],
      isDynamic: item.attributes[0]?.base?.is_dynamic,
    }))
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryIdsFromUrl = urlParams.getAll("category");
    const searchProduct = urlParams.get("search");

    if (categoryIdsFromUrl.length > 0) {
      // adding id to state
      categoryIdsFromUrl.map((category_id: string) => {
        setCategoryIDs((categoryIDs) => [...categoryIDs, category_id]);
      });
    }

    if (searchProduct) {
      searchApi(searchProduct);
    }
  }, []);
  const searchApi = async (searchProduct: any) => {
    try {
      const response = await apiCaller(
        `/api/productApi/?search=${searchProduct}`
      );

      setproductData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const priceFilterConcat = (url: string) => {
    let category_exists_in_url = url.match(/category/g);

    if ((minPrice !== 0 && maxPrice !== 0) || maxPrice !== 0) {
      if (categoryIDs.length !== 0 && category_exists_in_url === null) {
        // cat_id in url
        url = url + `&min_price=${minPrice}&max_price=${maxPrice}`;
        return url;
      } else if (category_exists_in_url) {
        url = url + `&min_price=${minPrice}&max_price=${maxPrice}`;
        return url;
      }
      url = url + `?min_price=${minPrice}&max_price=${maxPrice}`;
      return url;
    } else {
      return url;
    }
  };

  const apiCaller = async (url: any) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  };

  const categoryCheckboxHandler = async (e: any) => {
    let Url: string = "/api/productApi/";
    // checking whether category is checked or not
    if (e.target.checked) {
      // when checked
      if (categoryIDs.length !== 0) {
        // if category already selected add to url path
        var flag: number = 1;
        categoryIDs.map((cat_id) => {
          if (flag === 1) {
            flag = 0;
            Url = Url + `?category=${cat_id}`;
          } else {
            Url = Url + `&category=${cat_id}`;
          }
        });
        Url = `${Url}&category=${e.target.value}`;
        Url = priceFilterConcat(Url);
        let data = await apiCaller(Url);

        setproductData(data);
        setCategoryIDs((categoryIDs) => [...categoryIDs, e.target.value]);

        // window.location.href = Url;
      } else {
        Url = `${Url}?category=${e.target.value}`;
        Url = priceFilterConcat(Url);
        let data = await apiCaller(Url);
        setproductData(data);
        setCategoryIDs((categoryIDs) => [...categoryIDs, e.target.value]);

        // window.location.href = Url;
      }
    } else {
      // when unchecked
      let index = categoryIDs.indexOf(e.target.value);
      if (index !== -1) {
        // uncheck
        categoryIDs.splice(index, 1);
      }
      // routing to new page
      var flag: number = 1;
      if (categoryIDs.length > 0) {
        categoryIDs.map((cat_id) => {
          if (flag === 1) {
            flag = 0;
            Url = Url + `?category=${cat_id}`;
          } else {
            Url = Url + `&category=${cat_id}`;
          }
        });
        Url = priceFilterConcat(Url);
        let data = await apiCaller(Url);
        setproductData(data);
      } else {
        Url = priceFilterConcat(Url);
        let data = await apiCaller(Url);
        setproductData(data);
      }
    }
  };

  const priceFilterClear = async () => {
    let data = await apiCaller(apiEndpoint);
    setproductData(data);
    setMinPrice(0);
    setMaxPrice(0);
  };

  const priceFilterHandler = async () => {
    let Url: string = "/api/productApi/";

    if ((minPrice !== 0 && maxPrice !== 0) || maxPrice !== 0) {
      var flag: number = 1;
      if (categoryIDs.length > 0) {
        categoryIDs.map((cat_id) => {
          if (flag === 1) {
            flag = 0;
            Url = Url + `?category=${cat_id}`;
          } else {
            Url = Url + `&category=${cat_id}`;
          }
        });
        setAPIEndpoint(Url);
        Url += `&min_price=${minPrice}&max_price=${maxPrice}`;
        let data = await apiCaller(Url);
        setproductData(data);
      } else {
        setAPIEndpoint(Url);
        Url += `?min_price=${minPrice}&max_price=${maxPrice}`;
        let data = await apiCaller(Url);
        setproductData(data);
      }
    }
  };

  const onMinPriceChange = (e: any) => {
    setMinPrice(e.target.value);
  };
  const onMaxpriceChange = (e: any) => {
    setMaxPrice(e.target.value);
  };

  const handleExploreBtn = () => {
    window.location.href = `/`;
  };
  const imageConstant = ImageIcons.ProductList;
  
  return (
    <Scaffold title="Home">
      <>
        <NavBar />

        <div className="p-5 ml-0 sm:ml-0 md:px-20 lg:px-28">
          <h1 className="mr-0 md:mb-4 text-xl font-bold text-base-content md:text-2xl lg:text-3xl">
            All
            <span className="text-primary"> Products</span>
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2  ">
            <h1 className="text-base m-0 pl-4 hidden md:block lg:hidden text-base-content font-semibold">
              Showing results {productData.length === 0 ? 0 : 1} -{" "}
              {productData.length} of
              <span className="text-primary">
                {" "}
                {productData.length === 0 || productData.length == 1
                  ? productData.length + " results"
                  : productData.length + " results"}{" "}
              </span>
            </h1>
            <div className="drawer w-full block lg:hidden my-3 md:my-0">
              <input id="my-drawer" type="checkbox" className="drawer-toggle" />
              <div className="flex items-center space-x-2 drawer-content float-end scale-75 md:scale-100">
                {/* Page content here */}
                <p className="text-[17px] text-base-content">Filter</p>
                <label htmlFor="my-drawer" className="">
                  <Filter className="text-base-content cursor-pointer" />
                </label>
              </div>
              {/* drawer */}
              <div className="drawer-side z-30">
                <label
                  htmlFor="my-drawer"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>
                <ul className="menu p-4 w-52 min-h-full bg-base-100  text-base-100 md:w-80">
                  <FilterComponent>
                    <CategoryFilterComponent>
                      {props.categories.map((category) => (
                        <CategoryFilterField
                          pk={category.pk}
                          name={category.name}
                          existingCategoryIds={categoryIDs}
                          level={category.level}
                          onChange={categoryCheckboxHandler}
                        />
                      ))}
                    </CategoryFilterComponent>
                    <div className="divider"></div>
                    <PriceFilterComponent
                      maxPriceFromUrl={maxPrice}
                      minPriceFromUrl={minPrice}
                      onMaxPriceChange={onMaxpriceChange}
                      onMinPriceChange={onMinPriceChange}
                      priceFilterClearFunc={priceFilterClear}
                      priceFilterHandler={priceFilterHandler}
                    />
                  </FilterComponent>
                </ul>
              </div>
            </div>
          </div>
          <div className="container h-full">
            <div
              className="grid gap-x-2 grid-cols-1 md:grid-cols-2
                         lg:grid-cols-3 xl:grid-cols-4 md:scale-100 md:mt-8"
            >
              <div className=" col-span-1 w-[250px] p-2 hidden lg:block ">
                <FilterComponent>
                  <CategoryFilterComponent>
                    {props.categories.map((category) => (
                      <CategoryFilterField
                        pk={category.pk}
                        name={category.name}
                        existingCategoryIds={categoryIDs}
                        onChange={categoryCheckboxHandler}
                        level={category.level}
                      />
                    ))}
                  </CategoryFilterComponent>
                  <div className="divider"></div>
                  <PriceFilterComponent
                    maxPriceFromUrl={maxPrice}
                    minPriceFromUrl={minPrice}
                    onMaxPriceChange={onMaxpriceChange}
                    onMinPriceChange={onMinPriceChange}
                    priceFilterClearFunc={priceFilterClear}
                    priceFilterHandler={priceFilterHandler}
                  />
                </FilterComponent>
              </div>
              <div className="bg-blue col-span-1  md:col-span-2 lg:col-span-2 xl:col-span-3">
                <h1 className="hidden mb-8 lg:block text-black font-semibold">
                  Showing results {productData.length === 0 ? 0 : 1} -{" "}
                  {productData.length} of
                  <span className="text-primary">
                    {" "}
                    {productData.length === 0 || productData.length == 1
                      ? productData.length + " results"
                      : productData.length + " results"}{" "}
                  </span>
                </h1>
                {productData.length != 0 ? (
                  <div
                    className="grid grid-cols-2 justify-items-center  md:ml-0
                                min-[465px]:grid-cols-3 max-[450px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-y-7 gap-x-24 sm:gap-x-16 md:gap-x-2 lg:gap-y-12  "
                  >
                    {productData.map((product: any) => (
                     
                      <>
                        <div className="">
                          <ProductCard
                            category={product.category.name}
                            pk={product.pk}
                            price={product.price}
                            productName={product.name}
                            isDynamic={product.isDynamic}
                            image={
                              product.images?.length > 0
                                ? product.images[0].image
                                : ""
                            }
                          />
                        
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-center justify-center h-screen md:h-full text-center gap-y-4 md:gap-y-6 ">
                      <img
                        src={imageConstant.EmptyCart}
                        alt=""
                        className="w-24 h-24 md:w-32 md:h-32"
                      />
                      <div className="flex flex-col gap-y-1 md:gap-y-2">
                        <p className="text-base-content text-base font-bold md:text-xl">
                          Oops!
                        </p>
                        <p className="text-base-content text-xs font-medium md:text-sm">
                          No Products Available to Show here
                        </p>
                      </div>
                      <ButtonComponent
                        buttonText="Explore More"
                        coloredButton={true}
                        onClick={handleExploreBtn}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" md:px-12 lg:px-20">
          <Footer />
        </div>
      </>
    </Scaffold>
  );
};
