import Carousal from "@client/components/Carousal";
import CategoryCard from "@client/components/CategoryCard";
import React from "react";
import FeatureBoundary from "./FeatureBoundary";

interface ICategories {
  //   children: React.ReactNode;
  categories: any[];
}

export const FeaturedCategories = (props: ICategories) => {
  const colors = ["#E7F2E1", "#FBEAEA", "#F7F6D7", "#EEEEF9"];

  return (
    <FeatureBoundary flag="core.home.category">
      <div className="grid items-center lg:px-24 mt-10 md:mt-16 md:mb-16">
        <div className="flex justify-between   items-center">
          <div className="text-xl md:text-3xl font-bold  text-base-content">
            Our Popular <span className="text-primary">Categories</span>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 item-center justify-center gap-12 md:gap-12 lg:gap-12 ">
          {props.categories.length !== 0 ? (
            <Carousal>
              {props.categories.map((item, index) => {
                
                return (
                  <CategoryCard
                    productCount={item.prod_count}
                    categoryId={item.pk}
                    categoryImage={item.image}
                    categoryName={item.name}
                    bgColor={colors[index] ?? colors[0]}
                    
                  />
                );
              })}
            </Carousal>
          ) : (
            <EmptyCategory />
          )}
        </div>
      </div>
    </FeatureBoundary>
  );
};

function EmptyCategory() {
  return (
    <div className="flex h-48 md:h-56 items-center justify-center ">
      <p className="text-base-content font-semibold text-lg text-center md:text-2xl">
        No Categories available to show!!
      </p>
    </div>
  );
}
export default FeaturedCategories;
