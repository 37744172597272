import * as React from "react";
import type { SVGProps } from "react";
const SvgLogin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 21"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.041 10.25H1M10.113 7.334l2.928 2.916-2.928 2.916"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.733 5.617v-.933A3.684 3.684 0 0 1 9.418 1h4.884a3.675 3.675 0 0 1 3.675 3.675v11.14a3.685 3.685 0 0 1-3.685 3.685H9.407a3.675 3.675 0 0 1-3.674-3.675v-.942"
    />
  </svg>
);
export default SvgLogin;
