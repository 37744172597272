import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.2">
      <path
        d="M23 22.9999V14.5762C23 10.4362 22.1087 7.27368 17.2787 7.27368C14.95 7.27368 13.3975 8.53868 12.765 9.74618H12.7075V7.64743H8.13623V22.9999H12.9087V15.3812C12.9087 13.3687 13.2825 11.4424 15.755 11.4424C18.1987 11.4424 18.2275 13.7137 18.2275 15.4962V22.9712H23V22.9999Z"
        fill="currentColor"
      />
      <path
        d="M0.373779 7.64771H5.14628V23.0002H0.373779V7.64771Z"
        fill="currentColor"
      />
      <path
        d="M2.76 0C1.23625 0 0 1.23625 0 2.76C0 4.28375 1.23625 5.54875 2.76 5.54875C4.28375 5.54875 5.52 4.28375 5.52 2.76C5.52 1.23625 4.28375 0 2.76 0Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgLinkedin;
