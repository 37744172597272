import React, { useState } from "react";
import ImageIcons from "@client/constants/index";
import { Add } from "@client/assets/icons_svg";

interface IShippingAddressProps {
  addresses: any;
  selectedOption: any;
  handleRadioChange: any;
}

const ShippingAddress = (props: IShippingAddressProps) => {
  const page= "orders"
    const ImageConstants = ImageIcons.ShippingAddress;

  return (
    <div>
      {props.addresses.map((option: any) => (
        <div
          key={option.pk}
          className={`flex justify-between flex-row p-5   cursor-pointer  ${
            props.selectedOption === option.pk ? "bg-base-200" : ""
          }`}
        >
          <label htmlFor={option.pk} className="mr-4 flex">
            <input
              type="radio"
              id={option.pk}
              name="shipping-options"
              checked={props.selectedOption === option.pk}
              className="scale-50 mt-2 mr-4 radio radio-primary"
              onChange={() => props.handleRadioChange(option.pk)}
            />
            <div>
              <div className="text-base-content font-semibold text-base cursor-pointer">
                {option.name}
              </div>
              <div className="py-3 cursor-pointer font-light">
                {option.address +
                  ", " +
                  option.city.name +
                  ", " +
                  option.state.name}
              </div>
            </div>
          </label>
          {props.selectedOption === option.pk && (
            <a href={`/account/address/edit/${option.pk}/?page=${page}`}>
              Edit
            </a>
          )}
        </div>
      ))}
      <a
        className="flex gap-3 flex-row p-5 font-medium text-base-content"
        href={`/account/add/?page=${page}`}
      >
        <Add className="w-5 h-5 text-base-content" />
        <p>Add New Address</p>
      </a>
    </div>
  );
};

export default ShippingAddress;
