import * as React from "react";
import type { SVGProps } from "react";
const SvgRightarrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.456.459 9.028 1.865l5.104 4.983H.389v1.985h13.742l-5.103 4.984 1.428 1.407 7.56-7.383z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRightarrow;
