import React, { useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import "./categoryList.css";
import Footer from "@client/components/Footer";

import NewsLetter from "@client/components/NewsLetterComponent";
import BannerComponent from "@client/components/BannerComponent";
import AboutUs from "@client/components/AboutUs";
import FeaturedCategories from "@client/containers/FeaturedCategories";
import Offers from "@client/containers/Offers";
import FeaturedProducts from "@client/containers/FeaturedProducts";
import DealsShowcase from "@client/containers/DealsShowcase";
import Testimonials from "@client/containers/Testimonials";
import FeaturedServices from "@client/containers/FeaturedServices";

export default (props: templates.Home) => {
  const testimonialDummyData = [
    {
      name: "Tony stark",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
      comment: "Happy customer",
      rating: 3,
    },
    {
      name: "MJ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
      comment: "Happy customer",
      rating: 4,
    },
    {
      name: "Tony stark",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
      comment: "Happy customer",
      rating: 3,
    },
    {
      name: "Tony ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
      comment: "Happy customer",
      rating: 1,
    },
    {
      name: "Peter parker",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
      comment: "Happy customer",
      rating: 5,
    },
  ];

  return (
    <Scaffold title="Home">
      <NavBar />

      <div className="container mx-auto">
      
        <BannerComponent />
        <FeaturedCategories categories={props.categories} />
        <Offers />
        <FeaturedProducts products={props.products} />
        <DealsShowcase products={props.products} />
        <AboutUs />
        <Testimonials testimonials={testimonialDummyData} />
        <NewsLetter />
        {/* @ivin : what shouold be the bg-color for each service card? */}
        {/* TODO: @roshna can we ask sajeer about this */}
        <FeaturedServices />


        {/* TODO: @roshna does the div need to be here?? */}
        {/* <div className="md:px-12 lg:px-24 xl:px-0"> */}
          <Footer />
        {/* </div> */}
      </div>
    </Scaffold>
  );
};
