import React, { MouseEventHandler } from "react";
import ButtonComponent from "./ButtonComponent";

interface IPriceDetalisProps {
  price: number;
  numberOfItem: number;
  discount: number;
  total: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  showButton?: boolean;
  isCheckout?: boolean;
}
const PriceDetails = (props: IPriceDetalisProps) => {
  return (
    <div className="flex justify-center ">
      <div className="rounded-lg w-full md:w-80  shadow-lg">
        <div className="card-body">
          <div className=" border-b-[1px] py-3  border-neutral-300 flex items-center space-x-2 ">
            {props.isCheckout ? (
              <>
                <div className="text-nowrap text-base font-semibold text-base-content">
                  Price Details
                </div>
                <div className="w-full bg-neutral-300 h-0.5 ">
                  <div className="bg-primary h-0.5 rounded-full  w-1/3"></div>
                </div>
              </>
            ) : (
              <div className="text-nowrap text-base font-medium text-base-content">
                Price Details
              </div>
            )}
          </div>
          <div className="flex justify-between py-2">
            <div className={` text-base-content text-sm`}>
              Price
              {/* ({props.numberOfItem} Items) */}
            </div>
            <div className="font-semibold text-base-content text-sm ">
              ₹ &nbsp;{props.price}
            </div>
          </div>
          <div className="flex justify-between py-2">
            <div className="text-base-content text-sm">Discount</div>
            <div className="font-semibold text-base-content text-sm">
              ₹ &nbsp;-{props.discount}
            </div>
          </div>

          <div className="flex justify-between border-t-[1px] py-3 text-base font-semibold text-base-content border-base-200">
            <div>Total Amount</div>
            <div>₹ &nbsp;{props.total}</div>
          </div>
          {props.showButton ? (
            <ButtonComponent
              buttonText="Proceed to Checkout"
              coloredButton={true}
              onClick={(event) => props.onClick?.(event)}
            />
          ) : (
            <ButtonComponent
              buttonText="Make Payment"
              coloredButton={true}
              onClick={(event) => props.onClick?.(event)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
