import React from "react";
import ButtonComponent from "./ButtonComponent";
import ImageIcons from "@client/constants/index";
import { ArrowRight } from "@client/assets/icons_svg";

interface IOrderHistoryProps {
  items: any;
}

const ItemCount = (itemList: []) => {
  let item_count = 0;
  itemList.map((item:any) => {
    item_count += item?.quantity;
  });
  return item_count;
};

const OrderDate = (orderDate: any) => {
  const parsedDate: Date = new Date(orderDate);
  const month: string = parsedDate.toLocaleString("default", { month: "long" });
  const day: number = parsedDate.getDate();
  const year: number = parsedDate.getFullYear();

  return `${month} ${day}, ${year}`;
};

const OrderHistory = (props: IOrderHistoryProps) => {
    const ImageConstants = ImageIcons.OrderHistory;

  return (
    <div className="overflow-x-auto  p-0 lg:px-5 ">
      {/* larger screens */}
      <table className="hidden lg:block table table-auto w-full">
        <thead className=" md:block bg-base-200 text-base-content text-base">
          <tr className="grid grid-cols-6 p-3 border-b-base-200">
            <div className="col-span-2">Order ID</div>
            <div>Item Count</div>
            <div>Date Placed</div>
            <div>Total Cost</div>
            <div>Status</div>
          </tr>
        </thead>
        <tbody className="hidden lg:block ">
          {props.items.map((item: any) => (
            <>
              <div className="collapse  collapse-arrow  ">
                <input type="checkbox" />
                <div className={" collapse-title text-base-content p-0"}>
                  <tr className="hidden   md:grid  md:grid-cols-6 items-center p-5 text-base-content  text-sm border-b-base-200 ">
                    <div className="col-span-2">{item.razorpay_order_id}</div>
                    <div>
                      <>{ItemCount(item.orderitems)}</> items
                    </div>

                    <div>
                      <>{OrderDate(item.order_date)}</>
                    </div>
                    <div>₹ {item.total_cost}</div>

                    {item.status === 0 ? (
                      <div className="text-primary ">PAYMENT PENDING</div>
                    ) : item.status === 1 ? (
                      <div className="text-warning">ORDER PROCESSING</div>
                    ) : item.status === 2 ? (
                      <div className="text-primary">SHIPPED</div>
                    ) : item.status === 3 ? (
                      <div className="text-primary">DELIVERED</div>
                    ) : (
                      <div className="text-error">CANCELLED</div>
                    )}
                  </tr>
                </div>
                <div className={"collapse-content "}>
                  <div className="p-5  border-b-neutral-400 border border-transparent text-sm grid grid-cols-12">
                    <div className="col-span-10">
                      {item.orderitems.map((product: any) => (
                        <div className="justify-items-center grid grid-cols-3 items-center py-3 text-base-content font-semibold">
                          <div >{product.product.name}</div>
                          <div>
                            {product.quantity} X ₹ {product.product.price}
                          </div>
                          <div >
                            Total : ₹ {product.quantity * product.product.price}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-span-2 flex items-center  justify-end">
                      {item.status === 0 ? (
                        <ButtonComponent
                          buttonText="RETRY "
                          coloredButton={false}
                        />
                      ) : (
                        <ButtonComponent
                          buttonText="REORDER "
                          coloredButton={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </tbody>
      </table>

      {/* medium screens */}

      <div className=" hidden md:block overflow-x-auto lg:hidden">
        <table className="table">
          <thead>
            <tr className="bg-base-200 text-base-content text-base   md:grid md:grid-cols-4 ">
              <th className="col-span-2">Order ID</th>
              <th className="col-span-2 ml-10">Status</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item: any) => (
              <tr className="grid grid-cols-2 md:grid-cols-4 ">
                <td className=" text-base-content  text-sm col-span-2">
                  {item.razorpay_order_id}
                </td>
                <td className=" text-base-content  text-sm ">
                  {item.status === 0 ? (
                    <div className="text-primary ">PAYMENT PENDING</div>
                  ) : item.status === 1 ? (
                    <div className="text-warning">ORDER PROCESSING</div>
                  ) : item.status === 2 ? (
                    <div className="text-primary">SHIPPED</div>
                  ) : item.status === 3 ? (
                    <div className="text-primary">DELIVERED</div>
                  ) : (
                    <div className="text-error">CANCELLED</div>
                  )}
                </td>
                <td className="items-center row-span-2  text-base-content  text-sm cursor-pointer">
                  <a
                    href={`/orders/details/${item.id}/`}
                    className="cursor-pointer"
                  >
                    <ArrowRight className="  text-primary w-6 h-6" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* small screens */}

      <div className="flex flex-col md:hidden  ">
        {props.items.map((item: any) => (
          <div className="border-b-[1px]  border-neutral-500 p-5">
            <div className="mb-4 md:mb-0">
              <div className="flex items-center text-sm text-base-content">
                <div className="mr-2 font-bold">Order ID:</div>
                <div className="">{item.razorpay_order_id}</div>
              </div>
            </div>
            <div className="mb-4 md:mb-0">
              <div className="flex items-center text-sm text-base-content">
                <div className="mr-2 font-bold">Status:</div>

                {item.status === 0 ? (
                  <div className="text-warning ">PENDING</div>
                ) : item.status === 1 ? (
                  <div className="text-primary">PROCESSING</div>
                ) : (
                  <div className="text-error">CANCELLED</div>
                )}
              </div>
            </div>
            <div className="md:ml-4">
              <a
                href={`/orders/details/${item.id}/`}
                className="cursor-pointer"
              >
                <ArrowRight className="  text-primary w-6 h-6" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderHistory;
