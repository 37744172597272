import React, { useEffect, useState } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import SideNavbar from "@client/components/SideNav";
import { MakeDefaultAddress } from "@client/apis/Api";
import ImageIcons from "@client/constants/index";
import { Add, Delete, Pencil } from "@client/assets/icons_svg";
import RadioInput from "@client/components/RadioInput";

interface AddressTypeMapping {
  0: string;
  1: string;
}

const addressTypeMapping: AddressTypeMapping = {
  0: "Home",
  1: "Work",
};

export default (props: templates.UserAddressList) => {
  const [defaultAddress, setDefaultAddress] = useState(() =>
    props.addresses.find((address) => address.is_default)
  );
  const [addresses, setAddresses] = useState(props.addresses);
  const page = "address";
  const makeDefault = async (addressId: string) => {
    try {
      const response = await MakeDefaultAddress(addressId);
      const newAddresses = addresses.map((address) => {
        if (address.pk === addressId) {
          return { ...address, is_default: true };
        } else if (address.is_default) {
          return { ...address, is_default: false };
        } else {
          return address;
        }
      });
      console.log(response);
      const newDefaultAddress = props.addresses.find(
        (address) => address.pk === addressId
      );
      setDefaultAddress(newDefaultAddress);
      setAddresses(newAddresses);
    } catch (error) {
      console.log("error", error);
    }
  };
 const imageConstant = ImageIcons.UserAddressList;

  return (
    <Scaffold title="Address">
      <NavBar />
      <div className="mx-auto container">
        <div className="grid grid-cols-12 mt-16 md:px-16 relative min-h-[500px]">
          <div className=" bg-base-100 mt-10 md:mt-16  absolute top-0  left-0 border border-t-8 border-t-primary rounded border-base-200">
            <SideNavbar />
          </div>
          <div className="mt-10 col-span-12  ml-12 md:ml-40">
            <div className="lg:col-span-2">
              {addresses.length > 0 && (
                <div className="bg-formbg shadow-square-shadow p-3 md:p-7">
                  <div className="grid grid-cols-1 gap-4">
                    {addresses.map((item: any, index: any) => (
                      <div
                        key={index}
                        className={`${
                          item.pk === defaultAddress || item.is_default
                            ? "border border-primary bg-neutral-100 "
                            : "border border-base-200 bg-base-100"
                        } p-3 md:p-4 text-base-content`}
                      >
                        <div className="flex justify-between items-center">
                          <div className="text-base-content text-xs md:text-sm bg-base-300 font-normal md:font-medium p-1 md:p-2 ">
                            <p>
                              {
                                addressTypeMapping[
                                  item.address_type as keyof AddressTypeMapping
                                ]
                              }
                            </p>
                          </div>

                          <div className="flex space-x-8">
                            <div
                              className={`${
                                item.pk === defaultAddress || item.is_default
                                  ? "hidden"
                                  : "block"
                              } flex items-center`}
                            >
                              <input
                                name="is_default"
                                value="#"
                                type="radio"
                                onClick={() => makeDefault(item.pk)}
                                className="radio radio-primary cursor-pointer scale-50"
                              />
                              <label className="text-xs md:text-sm">
                                Make default
                              </label>
                            </div>
                            <a href={`/account/address/delete/${item.pk}`}>
                              <Delete className="w-5 h-5 text-base-content" />
                            </a>
                            <a href={`/account/address/edit/${item.pk}`}>
                              <Pencil className="w-5 h-5 text-base-content" />
                            </a>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row py-4 space-x-0 md:space-x-6 text-base-content font-medium md:font-semibold text-sm md:text-base">
                          <p>{item.name}</p>
                          <p>{item.phone_number}</p>
                        </div>
                        <div className="flex flex-wrap md:flex-nowrap text-xs md:text-sm">
                          <div className="flex flex-wrap">
                            <p>{item.address} ,</p>
                            <p>{item.landmark && `${item.landmark} ,`}</p>
                            <p>{item.locality} ,</p>
                            <p>{item.city.name} ,</p>
                            <p>{item.state.name} ,</p>
                            <p>
                              {item.optional_phn_num &&
                                `${item.optional_phn_num} ,`}
                            </p>
                            <p>{item.pin_code}</p>
                          </div>
                        </div>
                        <div className="flex space-x-12 justify-center items-center border-t-2 border-neutral pt-4 mt-4 md:hidden">
                          <a href={`/account/address/delete/${item.pk}`}>
                            <img src={imageConstant.Delete} alt="delete" />
                          </a>
                          <a href={`/account/address/edit/${item.pk}`}>
                            <img src={imageConstant.Edit} alt="Edit" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <a
                className="flex space-x-5 border-gray-50 lg:col-span-2 bg-formbg shadow-square-shadow p-7 mt-10 font-bold text-base text-base-content"
                href={`/account/add/?page=${page}`}
              >
                <Add className="w-5 h-5 text-base-content" />
                <p className="ml-2">Add New Address</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-20 xl:px-24  hidden lg:block ">
        <Footer />
      </div>
    </Scaffold>
  );
};