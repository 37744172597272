import * as React from "react";
import type { SVGProps } from "react";
const SvgRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="m3.047.024 10.537 10.4L3.126 20.922l-2.45-2.44 8.004-8.035-8.064-7.96z"
    />
  </svg>
);
export default SvgRightArrow;
