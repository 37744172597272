import React, { MouseEventHandler } from "react";
interface IButtonProps {
  buttonText: string;
  coloredButton: Boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const ButtonComponent = (props: IButtonProps) => {
  return (
    <>
      <button
        className={
          props.coloredButton
            ? "p-3 rounded-md text-sm border border-primary bg-primary text-primary-content normal-case hover:shadow-lg"
            : "p-3 rounded-md text-sm  normal-case text-primary border border-primary hover:shadow-lg"
        }
        onClick={props.onClick}
      >
        {props.buttonText}
      </button>
    </>
  );
};

export default ButtonComponent;
