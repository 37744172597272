import React, { useCallback, useContext } from "react";
import { templates, Context } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import { NavBar } from "@client/components/Navbar";
import Footer from "@client/components/Footer";
import ProductCard from "@client/components/ProductCard";

export default (props: templates.WishList) => {
  const { user } = useContext(Context);
  const productList = [
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 25,
      actualPrice: 100,
      discount: 25,
      image: "pngegg_1.png",
    },
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "pngegg.png",
    },
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "brocoli.png",
    },

    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "pngegg.png",
    },
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 25,
      actualPrice: 100,
      discount: 25,
      image: "pngwing.com_BA7GFq9.png",
    },
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "brocoli.png",
    },
    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "pngwing.com_1.png",
    },

    {
      pk: "1",
      productName: "Farm Fresh Organic Tomato 250g",
      category: "VEGETABLES",
      price: 45,
      actualPrice: 100,
      discount: 25,
      image: "pngegg_1.png",
    },
  ];
  return (
    <Scaffold title="WishList">
      <NavBar />
      <div className=" px-4 mt-24 md:px-16 lg:px-24 xl:px-32">
        <div className=" text-2xl  font-bold text-base-content md:py-5 ml-7 md:ml-0">
          My <span className="text-primary">Wishlist</span>{" "}
          <span className="text-neutral-500 font-normal text-lg ml-2">
            (12)
          </span>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-4 lg:gap-8 items-center justify-center">
          {productList.map((item) => (
            <ProductCard
              price={item.price}
              productName={item.productName}
              image={item.image}
              isLiked={true}
              pk={item.pk}
              category={item.category}
            />
          ))}
        </div>
      </div>
      <div className="md:px-16 lg:px-24">
        <Footer />
      </div>
    </Scaffold>
  );
};
