import * as React from "react";
import type { SVGProps } from "react";
const SvgDropDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7745 1.75776L6.23677 7.29597L1.20955 1.65917"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
);
export default SvgDropDown;
