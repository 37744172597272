import * as React from "react";
import type { SVGProps } from "react";
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E6E6E6" />
    <path
      fill="#BFCCD7"
      d="M20.018 22.448c4.735 0 8.732.741 8.732 3.609 0 2.865-4.022 3.582-8.732 3.582l-.321-.001c-4.593-.034-8.41-.806-8.41-3.608 0-2.866 4.022-3.582 8.731-3.582m0-13.884c3.208 0 5.778 2.481 5.778 5.576 0 3.096-2.57 5.576-5.778 5.576-3.206 0-5.777-2.48-5.777-5.576s2.571-5.576 5.777-5.576"
    />
  </svg>
);
export default SvgProfile;

