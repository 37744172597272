import axios from "axios";

const createInstance = (baseURL:any) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((req) => {
    req.headers["Content-Type"] = "application/json";
    return req;
  });

  return instance;
};

const instanceOrder = createInstance("/api/");
const instanceUser = createInstance("/account/");
const instanceProduct = createInstance("/api/");

export { instanceOrder, instanceUser, instanceProduct };
