import React from "react";
import { CSRFToken, templates } from "@reactivated";
import { Scaffold } from "@client/containers/Scaffold";
import PasswordInput from "@client/components/PasswordInput";
import { AuthBox } from "@client/containers/AuthBox";
export default (props: templates.ResetPassword) => {
  let form = props.form;
  console.log(form);

  return (
    <Scaffold title="rest_password">

      <AuthBox>
        <div className="flex flex-col md:shrink-0 mt-12 mb-8">

          <p className="text-2xl sm:text-3xl font-semibold text-base-content">
            Reset
          </p>
          <p className="text-2xl sm:text-3xl font-semibold text-base-content">
            Your Password?
          </p>

          <p className="font-light text-sm  text-base-content mt-3">
            What would you like your new password to be?
          </p>
        </div>
        <form  method="POST">

          <CSRFToken />
          <PasswordInput
            label="password"
            name="password"
            placeholder="Password"
            form={props.form}
          />
          <PasswordInput
            label="password"
            name="confirm_password"
            placeholder="Confirm Password"
            type="password"
            form={props.form}
          />
          <div className="grid grid-cols-1 gap-4 pb-20">
            <button
              className="w-full h-10 sm:h-12 px-4 py-2 font-medium text-white bg-primary rounded hover:shadow-md"
              type="submit"
            >
              Reset Password
            </button>
          </div>

        </form>
      </AuthBox>
      
    </Scaffold>
  );
};