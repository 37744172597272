import { instanceOrder, instanceProduct, instanceUser } from "./Axios";

export const AddToCart = (data: any) => {
  return instanceOrder.post("/addcart/", data);
};
export const RemoveCartItem = (id: number) => {
  return instanceOrder.delete(`/cartitem/${id}/`);
};
export const ClearCart = () => {
  return instanceOrder.delete(`/cartitem/clearCart/`);
};
export const IncrementCartQuantity = (id: number) => {
  return instanceOrder.put(`/cartitem/${id}/incrementQuantity/`);
};
export const DecrementCartQuantity = (id: number) => {
  return instanceOrder.put(`/cartitem/${id}/decrementQuantity/`);
};
export const CreateOrder = (data: any) => {
  return instanceOrder.post(`/orderitem/createOrder/`, data);
};
export const MakeDefaultAddress = (addressId: string) => {
  return instanceUser.post(`/api/default_address/?address_id=${addressId}`);
};
export const getCityList = (data: string) => {
  return instanceUser.get(`/api/get_cities/?state_id=${data}`);
};
export const checkoutWithSelection = (id: string) => {
  return instanceOrder.put(`/cartitem/${id}/checkout/`);
};

export const AddToCartWithdynamicValue = (id: any, data: any) => {
  return instanceProduct.post(
    `/attribute-list/${id}/setDynamicValue/`,
    data
  );
};

export const AddToCartWithImageValue = (id: any, data: any) => {
  return instanceProduct.post(
    `/update-image-attribute/${id}/updateDynamicImageValue/`,
    data
  );
};


export const getAllCategories = () => {
  return instanceProduct.get(`category-tree/`);
};
export const getAllSubCategories = (id:any) => {
  return instanceProduct.get(`category-tree/?parent_id=${id}`);
};