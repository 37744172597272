import * as React from "react";
import type { SVGProps } from "react";
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg  
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5714 10.2857H11.4286V16.7143C11.4286 17.0553 11.2781 17.3823 11.0102 17.6234C10.7422 17.8645 10.3789 18 10 18C9.62112 18 9.25776 17.8645 8.98985 17.6234C8.72194 17.3823 8.57143 17.0553 8.57143 16.7143V10.2857H1.42857C1.04969 10.2857 0.686328 10.1503 0.418419 9.90914C0.15051 9.66802 0 9.34099 0 9C0 8.65901 0.15051 8.33198 0.418419 8.09086C0.686328 7.84974 1.04969 7.71429 1.42857 7.71429H8.57143V1.28571C8.57143 0.944722 8.72194 0.617695 8.98985 0.376577C9.25776 0.135458 9.62112 0 10 0C10.3789 0 10.7422 0.135458 11.0102 0.376577C11.2781 0.617695 11.4286 0.944722 11.4286 1.28571V7.71429H18.5714C18.9503 7.71429 19.3137 7.84974 19.5816 8.09086C19.8495 8.33198 20 8.65901 20 9C20 9.34099 19.8495 9.66802 19.5816 9.90914C19.3137 10.1503 18.9503 10.2857 18.5714 10.2857Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAdd;
