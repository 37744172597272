import React, { useState } from "react";

interface IAccordionProps {
  children: React.ReactNode;
  title?: string;
  defaultChecked?: boolean;
  nestedAccordion?: string;
}
const AccordionComponent = (props: IAccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.defaultChecked || false);

  const handleAccordionToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={props.nestedAccordion ? "" : "border border-neutral-300"}>
      <div className="collapse  collapse-arrow  ">
        <input
          type="checkbox"
          name={
            props.nestedAccordion ? props.nestedAccordion : "parent-accordion"
          }
          checked={isOpen}
          onChange={handleAccordionToggle}
        />
        <div
          className={
            props.nestedAccordion
              ? " collapse-title text-base font-medium text-base-content "
              : "collapse-title text-base font-medium text-base-content"
          }
        >
          {props.title}
        </div>
        <div className={"collapse-content  "}>{props.children}</div>
      </div>
    </div>
  );
};

export default AccordionComponent;
